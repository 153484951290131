import { ref, reactive } from 'vue'
export const actionAvailableDict: { [key: string | number]: string[] } = {
  0: ['关机', '转包年包月', '修改SSH密码', '跨实例拷贝数据'],
  1: ['关机', '转包年包月', '修改SSH密码', '跨实例拷贝数据'],
  2: ['关机', '转包年包月', '修改SSH密码', '跨实例拷贝数据'],
  3: ['关机', '转包年包月', '修改SSH密码', '跨实例拷贝数据'],
  4: ['转包年包月', '修改SSH密码', '跨实例拷贝数据'],
  5: ['开机', '无卡模式开机', '更换镜像', '保存镜像', '扩缩容数据盘', '转包年包月', '克隆实例', '跨实例拷贝数据', '修改SSH密码', '重置系统', '释放实例'],
  7: ['关机', '转包年包月', '修改SSH密码'],
  9: ['转包年包月', '修改SSH密码']
}

const isShowChangeSSHPasswordDialog = ref(false) // 修改SSH密码弹窗
const isShowChangeInstanceNickName = ref(false) // 修改实例名称弹窗
const isShowResetSystem = ref(false) // 重置系统弹窗
const isShowChangeImageDialog = ref(false) // 更换镜像弹窗
const isShowPreservationImageDialog = ref(false) // 保存镜像弹窗
const isShowConfirmReleaseDialog = ref(false) // 释放实例弹窗
const isShowDiskExpansion = ref(false) // 扩容弹窗
const isShowCustomServerDialog = ref(false) // 自定义服务弹窗
export const currentlyOperatingOption = ref('') // 当前操作选项
export const currMechineId = ref('') // 当前实例ID

export const dialogVisibleDict: {
  [key: string]: any
} = reactive({
  修改SSH密码: isShowChangeSSHPasswordDialog.value,
  修改实例名称: isShowChangeInstanceNickName.value,
  重置系统: isShowResetSystem.value,
  更换镜像: isShowChangeImageDialog.value,
  保存镜像: isShowPreservationImageDialog.value,
  释放实例: isShowConfirmReleaseDialog.value,
  扩容: isShowDiskExpansion.value,
  自定义服务: isShowCustomServerDialog.value
})

export function clearDialogVisible() {
  dialogVisibleDict[currentlyOperatingOption.value] = false
  currMechineId.value = ''
}
export function getIsDisable(status: string | number, action: string) {
  return !actionAvailableDict[status]?.includes(action)
}
export function getIsAllowStopByAvailableDict(status: string | number) {
  return actionAvailableDict[status]?.includes('关机')
}
export function getIsAllowStartByAvailableDict(status: string | number) {
  return actionAvailableDict[status]?.includes('开机')
}
