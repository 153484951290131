<script setup lang="ts">
import { ElDropdown, ElDropdownMenu, ElDropdownItem, ElIcon } from 'element-plus'
import { ArrowDown } from '@element-plus/icons'
import router from '@/router'
import Cookies from 'js-cookie'
import { ref, onBeforeMount } from 'vue'
import oidc from '@/apis/auth'

const access_token = Cookies.get('access_token')
const username = ref()
onBeforeMount(async () => {
  if (Cookies.get('access_token')) {
    let res = await oidc.getUserInfo()
    Cookies.set('username', res.phone_number, {
      domain: '.gpufree.cn'
    })
    username.value = res.phone_number
  }
})

function logout() {
  oidc.logout()
  Cookies.remove('access_token')
  Cookies.remove('id_token')
  Cookies.remove('refresh_token')
  // router.replace('/login')
}
function goLogin() {
  router.push('/login')
}
</script>

<template>
  <div class="BaseUserBox">
    <el-dropdown v-if="access_token">
      <span class="el-dropdown-link">
        {{ username }}
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <div class="loginOrRegistry" v-else>
      <span @click="goLogin">注册/登录</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.BaseUserBox {
  cursor: pointer;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--theme-color);
  }

  .loginOrRegistry {
    cursor: pointer;

    span {
      color: var(--theme-active-color);
      padding: 4px 6px;
      border-radius: 8px;
      border: 1px solid var(--theme-active-color);
      font-size: 14px;

      &:hover {
        background: var(--theme-active-color);
        color: #ffffff;
      }
    }
  }
}
</style>
<style>
.el-icon--right {
  position: relative;
  top: 2px;
}
</style>
