import { get, post } from '@/utils/request/request'

// 获取开发机列表
export const getDevMachineList = () => {
  return get('/api/v1/jupyter/list_instance')
}
export const getDevMachineList_pages = (params: API.GetDevMachineListPages) => {
  return get<Response.Distance.ResponseData>('/api/v1/jupyter/list_instance_pages', params)
}
// 创建开发机
export const createDevMachine = (params: API.CreateDevMachine) => {
  return post('/api/v1/jupyter/create', params)
}
// 开机
export const startDevMachine = (params: API.DevMachineBaseHandler) => {
  return post('/api/v1/jupyter/power_on', params)
}
// 关机
export const stopDevMachine = (params: API.DevMachineBaseHandler) => {
  return post('/api/v1/jupyter/power_off', params)
}
// 释放开发机
export const releaseDevMachine = (params: API.DevMachineBaseHandler) => {
  return post('/api/v1/jupyter/release', params)
}
// 修改开发机别名
export const modifyDevMachineName = (params: { web_ide_instance_id: string; nick_name: string }) => {
  return post('/api/v1/jupyter/modify_name', params)
}
// 修改ssh密码
export const modifyDevMachinePassword = (params: API.ModifyDevMachinePassword) => {
  return post('/api/v1/jupyter/modify_password', params)
}
// 修改开发机启动镜像
export const modifyDevMachineImage = (params: API.ModifyDevMachineImage) => {
  return post('/api/v1/jupyter/change_image', params)
}
// 获取开发机详情
export const getDevMachineDetail = (params: API.DevMachineBaseHandler) => {
  return get('/api/v1/jupyter/spec_detail', params, { isThrottle: true, localcache: true })
}
// 获取开发机状态字典
export const getDevMachineStatusTag = () => {
  return get('/api/v1/jupyter/status_tag')
}
export const getAllImages = () => {
  return get('/api/v1/images/public/list')
}
// 获取公钥
export const getPublicKey = () => {
  return get<Response.SSHKey.ResponseData[]>('/api/v1/pub_key/list')
}
// 添加公钥
export const addPublicKey = (params: API.AddPublicKey) => {
  return post('/api/v1/pub_key/add', params)
}
// 删除公钥
export const deletePublicKey = (params: API.DeletePublicKey) => {
  return post('/api/v1/pub_key/delete', params)
}
// 重置系统
export const resetDevMachine = (params: API.DevMachineBaseHandler) => {
  return post('/api/v1/jupyter/reset', params)
}
// 保存镜像
export const saveImage = (params: { webide_run_id: string; image_name: string }) => {
  return post('/api/v1/jupyter/save_private_image', params)
}
