<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElTabs, ElTabPane, ElTable, ElTableColumn, ElPopover, ElButton, ElForm, ElFormItem, ElDialog, ElInput } from 'element-plus'
import { getPublicKey, addPublicKey, deletePublicKey } from '@/apis/devMachine'
import autolog from 'autolog.js'
import moment from 'moment'
import { copyInnerText, str2Asterisk } from '@/utils'
import ConfirmDialog from '../InstancesCom/ConfirmDialog/ConfirmDialog.vue'

const activeName = ref('SSH')
const isShowNewSSHKey = ref(false)
const isShowNewAPIToken = ref(false)
const isShowConfirmDialog = ref(false)

const tableData = ref<Response.SSHKey.ResponseData[]>([])
const tableData1 = ref([])
const form_SSH = ref({
  SSHKey: '',
  remark: ''
})
const rules_SSH = {
  SSHKey: [{ required: true, message: '请输入SSH公钥', trigger: 'blur' }],
  remark: [{ required: false, message: '请输入备注', trigger: 'blur' }]
}
const form_APITOKEN = {
  remark: ''
}
const rules_APITOKEN = {
  remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
}
const currentId = ref('')

onMounted(() => {
  getPublicKeyList()
})

const newSomething = () => {
  form_SSH.value.SSHKey = ''
  form_SSH.value.remark = ''
  if (activeName.value === 'SSH') {
    isShowNewSSHKey.value = true
  } else {
    isShowNewAPIToken.value = true
  }
}
const rmItem = (id: string) => {
  if (activeName.value === 'SSH') {
    deletePublicKeyItem(id)
  } else {
    console.log('rmItem API TOKEN')
  }
}

function getPublicKeyList() {
  getPublicKey().then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.map((item) => {
        return {
          id: item.id,
          public_key: item.public_key,
          creatDate: item.created_at,
          remark: item.note,
          created_at: item.created_at,
          note: item.note
        }
      })
    }
  })
}
function addPublicKeyItem() {
  addPublicKey({
    pub_key: form_SSH.value.SSHKey,
    notes: form_SSH.value.remark
  }).then((res) => {
    if (res.code == 200) {
      getPublicKeyList()
      isShowNewSSHKey.value = false
      autolog.log('新增成功', 'success')
    }
  })
}
function deletePublicKeyItem(id: string = currentId.value) {
  deletePublicKey({
    id
  }).then((res) => {
    if (res.code == 200) {
      getPublicKeyList()
      autolog.log('删除成功', 'success')
    }
    isShowConfirmDialog.value = false
  })
}
function findRemark() {
  const reg = /@([^@]*)$/
  const result = reg.exec(form_SSH.value.SSHKey)
  if (result) {
    form_SSH.value.remark = result[1]
  }
}
function pullupDialog(id: string) {
  currentId.value = id
  isShowConfirmDialog.value = true
}
</script>

<template>
  <div class="SecretsComBox">
    <div class="body">
      <div class="header">
        <span>密钥管理</span>
      </div>
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="&nbsp;&nbsp;&nbsp;SSH&nbsp;&nbsp;&nbsp;" name="SSH">
          <div class="options">
            <div class="tip warn">
              <span class="iconfont icon-tishi"></span>
              <span>修改的公钥会在容器重启后或者下次开机后生效。</span>
            </div>
            <span class="new" @click="newSomething">新增</span>
          </div>
          <el-table :data="tableData" style="width: 100%" header-row-class-name="tableHeader">
            <el-table-column prop="public_key" label="Public Key">
              <template #default="{ row }">
                <span>{{ str2Asterisk(row.public_key, 7, 7) }}</span>
                <span class="iconfont icon-fuzhi" @click="copyInnerText(row.public_key)" style="cursor: pointer; padding-left: 10px"></span>
              </template>
            </el-table-column>
            <el-table-column prop="creatDate" label="创建日期">
              <template #default="{ row }">
                {{ moment(row.creatDate).format('YYYY-MM-DD HH:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" />
            <el-table-column fixed="right" label="操作" min-width="120">
              <template #default="scope">
                <el-button link type="primary" size="small" @click="pullupDialog(scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="&nbsp;&nbsp;&nbsp;API TOKEN&nbsp;&nbsp;&nbsp;" name="API TOKEN" v-if="false">
          <el-table :data="tableData1" style="width: 100%">
            <el-table-column prop="token" label="API TOKEN" />
            <el-table-column prop="createDate" label="创建日期" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column fixed="right" label="操作" min-width="120">
              <template #default="scope">
                <el-popover :visible="scope.row.visible" placement="top" :width="160" trigger="click">
                  <p>你确定删除该条目吗?</p>
                  <br />
                  <div style="text-align: right; margin: 0">
                    <el-button size="small" text @click="scope.row.visible = false">取消</el-button>
                    <el-button
                      size="small"
                      type="danger"
                      @click="
                        () => {
                          rmItem(scope.row.fileName)
                          scope.row.visible = false
                        }
                      ">
                      确认
                    </el-button>
                  </div>
                  <template #reference>
                    <el-button link type="primary" size="small" @click="scope.row.visible = true">删除</el-button>
                  </template>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <el-dialog v-model="isShowNewSSHKey" title="新增 SSH 公钥" width="700" center>
    <div class="newSSHKey">
      <el-form ref="ruleFormRef" :model="form_SSH" :rules="rules_SSH" label-position="right">
        <el-form-item label="SSH公钥" prop="SSHKey" label-position="right">
          <el-input type="textarea" :rows="12" resize="none" placeholder="SSH公钥" v-model="form_SSH.SSHKey" style="padding-right: 60px" @input="findRemark" />
        </el-form-item>
        <el-form-item label="备注" label-position="right">
          <el-input placeholder="备注" v-model="form_SSH.remark" style="padding-right: 60px" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <span @click="isShowNewSSHKey = false" class="cancel">取消</span>
        <span type="primary" @click="addPublicKeyItem" class="confirm"> 确认 </span>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="isShowNewAPIToken" title="新增 API TOKEN" width="500" center>
    <el-form ref="ruleFormRef" :model="form_APITOKEN" :rules="rules_APITOKEN">
      <el-form-item label="备注" prop="remark">
        <el-input style="height: 114px; padding-right: 44px" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <span @click="isShowNewAPIToken = false" class="cancel">取消</span>
        <span type="primary" @click="isShowNewAPIToken = false" class="confirm"> 确认 </span>
      </div>
    </template>
  </el-dialog>
  <ConfirmDialog v-if="isShowConfirmDialog" titleIcon="icon-tishi" iconColor="#c00b22" title="删除" @cancel="isShowConfirmDialog = false" @confirm="deletePublicKeyItem">
    <div>删除公钥将在实例下一次重启后生效，此公钥对应的设备将不再能够免密SSH登录</div>
    <br />
    <div>确认删除？</div>
  </ConfirmDialog>
</template>

<style lang="less" scoped>
.SecretsComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .newSSHKey {
    padding-bottom: 36px;
  }

  .body {
    background: #fff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-shadow: 0px 2px 6px 0px #00000029;
    border-radius: 8px;
    border: 1px solid #ebebeb;

    .header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 36px;
    }

    .options {
      padding: 4px 0;
      padding-bottom: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .new {
        background: var(--theme-main-color);
        padding: 4px 18px;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        height: max-content;
      }
    }
  }
}
</style>
<style lang="less">
.SecretsComBox {
  .el-tabs__active-bar.is-top {
    height: 4px;
  }
}

.SecretsComBox + div {
  .el-dialog__header.show-close {
    padding-bottom: 36px;
    padding-top: 34px;
  }

  .el-dialog__footer {
    padding-bottom: 52px;
    padding-top: 36px;

    .cancel,
    .confirm {
      cursor: pointer;
      padding: 8px 46px;
      border-radius: 4px;
      color: #fff;
    }

    .cancel {
      background: #e5e7eb;
      margin-right: 20px;
      color: #4e5969;
    }

    .confirm {
      background: var(--theme-main-color);
    }
  }
}

.el-form-item__label {
  min-width: 126px;
}
</style>
