export default {
  header: [
    {
      prop: 'webide_instance_uuid',
      label: '实例ID/名称',
      minWidth: 180
    },
    {
      prop: 'status',
      label: '状态',
      minWidth: 100
    },
    {
      prop: 'cpu',
      label: '规格详情',
      minWidth: 120
    },
    {
      prop: 'local_disk_info',
      label: '存储',
      minWidth: 180
    },
    {
      prop: 'charge_type',
      label: '付费方式'
    },
    {
      prop: 'release_time',
      label: '释放/到期时间',
      minWidth: 180
    },
    {
      prop: 'ssh_command',
      label: 'SSH',
      minWidth: 120
    },
    {
      prop: 'jupyter_url',
      label: '快捷工具'
    },
    {
      prop: 'data_center_name',
      label: '操作',
      minWidth: 180
    }
  ],
  chargeTypeDict: {
    '0': '按量付费',
    '1': '包年包月'
  },
  machineDetailDict: {
    gpu_memory: {
      label: '显存',
      type: 'GB'
    },
    cpu_per_gpu: {
      label: 'CPU'
    },
    mem_per_gpu: {
      label: '内存',
      type: 'GB'
    },
    disk_type: {
      label: '硬盘类型'
    },
    sys_disk_size: {
      label: '系统盘',
      type: 'GB'
    },
    free_data_disk_size: {
      label: '剩余数据盘',
      type: 'GB'
    },
    expand_data_disk: {
      label: '扩展数据盘',
      type: 'GB'
    },
    image_name: {
      label: '镜像名称'
    },
    image_version: {
      label: '镜像版本'
    }
  }
} as {
  header: { prop: string; label: string; minWidth?: number }[]
  chargeTypeDict: { [key: string]: string }
  machineDetailDict: { [key: string]: { label: string; type?: string } }
}
