<script setup lang="ts">
import { ElDialog, ElButton } from 'element-plus'
import { onMounted, onUnmounted } from 'vue'
const { title, titleIcon, iconColor } = defineProps<{
  title: string
  titleIcon?: string
  iconColor?: string
}>()
const emit = defineEmits()
const isShow = true
onMounted(() => {
  window.addEventListener('keydown', keyDown)
})
onUnmounted(() => {
  window.removeEventListener('keydown', keyDown)
})

function keyDown(e: KeyboardEvent) {
  if (e.key === 'Enter') {
    confirm()
  }
}

function cancel() {
  emit('cancel')
}
function confirm() {
  emit('confirm')
}
function destroy() {
  cancel()
}
</script>

<template>
  <div class="ConfirmDialogBox">
    <el-dialog v-model="isShow" :title="title" width="max-content" style="min-width: 500px" @closed="destroy">
      <slot></slot>
      <template #header>
        <div class="fz18 colo#303133">
          <span v-if="titleIcon" class="iconfont" :class="titleIcon" :style="{ color: iconColor || 'var(--theme-main-color)' }"></span>
          {{ title }}
        </div>
      </template>
      <template #footer>
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="confirm" id="confirmDialogConfirmBtn"> 确认 </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.ConfirmDialogBox {
  box-sizing: border-box;
  :deep(.el-dialog) {
    .el-dialog__body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      position: relative;
      margin: 0 22px;
    }
    .el-dialog__footer {
      border-top: 1px solid #ebeef5;
      margin-top: 20px;
    }
    .el-dialog__header {
      padding-bottom: 24px;
    }
  }
}
</style>
