<script setup lang="ts">
import { ElTable, ElTableColumn, ElDropdown, ElDropdownMenu, ElDropdownItem, ElPopover, ElInput, ElPagination, ElIcon, ElCascader, ElInputNumber } from 'element-plus'
import { ArrowDown } from '@element-plus/icons'
import table from './table' // 表格表头、字典数据
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { bytes2GB, copyInnerText } from '@/utils'
import { dialogVisibleDict, currentlyOperatingOption, clearDialogVisible, getIsDisable, getIsAllowStopByAvailableDict, getIsAllowStartByAvailableDict, currMechineId } from './dict' // 操作权限、弹窗状态 字典和方法都在这里
import ConfirmDialog from './ConfirmDialog/ConfirmDialog.vue'
import {
  getDevMachineList_pages, // 获取实例列表_分页
  startDevMachine, // 开机
  stopDevMachine, // 关机
  releaseDevMachine, // 释放实例
  modifyDevMachineName, // 修改实例名称
  modifyDevMachineImage, // 更换镜像
  getDevMachineDetail, // 获取实例详情
  getDevMachineStatusTag, // 获取实例状态标签
  modifyDevMachinePassword, // 修改SSH密码
  resetDevMachine, // 重置系统
  getAllImages, // 获取所有镜像
  saveImage // 保存镜像
} from '@/apis/devMachine'
import autolog from 'autolog.js'
import moment from 'moment'
import router from '@/router'

const slowTimer = 5000 // 轮询时间
const fastTimer = 1000 // 快速轮询时间，用于中间状态时刷新
const pageSize = 7 // 每页显示数量

const tableData = ref<Response.Distance.DistanceDataList[]>([] as Response.Distance.DistanceDataList[])
const currSort = ref('1') // 1:升序 2:降序
const currPage = ref(1)
const tatolPage = ref(0)
const autoPollingTimer = ref(slowTimer)
const statusDict = ref<{
  [key: number]: {
    label: string
    color: string
    icon: string
  }
}>({})
let inervalTimer: NodeJS.Timeout | null = null

const currFilter = ref<string[]>([])
const currDetail = ref<instanceDetail>({} as instanceDetail) // 当前实例详情
const deTailLoading = ref(false) // 实例详情加载状态
const newImageId = ref('') // 新镜像ID
const newSSHPassword = ref('') // 新SSH密码
const newInstanceNickName = ref('') // 新实例名称
const imagesTree = ref<imagesCascader>([]) // 镜像树
const currImageNotes = ref('') // 当前镜像备注
const confirmedMechineId = ref('') // 确认释放实例ID
const diskExpansionSize = ref(0) // 扩容容量
const searchByNickNameStr = ref('') // 搜索实例名称
const loading = ref(true) // 加载状态

const query = router.currentRoute.value.query
currFilter.value = query.status ? ([query.status] as string[]) : []
currSort.value = (query.sort as string) || ''

const currMechine = computed(() => {
  return tableData.value.find((item) => item.webide_instance_id == currMechineId.value)
})
const currStatus = computed(() => {
  let status = currFilter.value.join(',')
  if (status == '0124689') {
    return '-2'
  }
  return currFilter.value.join(',')
})

onMounted(async () => {
  getDevMachineStatusTag().then((res) => {
    for (let item of res.data) {
      statusDict.value[item.id] = item
    }
  })
  const res = await getDevMachineList_pages({
    page_no: currPage.value,
    page_size: pageSize,
    stop_time_order: currSort.value,
    status: currStatus.value,
    nick_name: searchByNickNameStr.value
  })
  // 长度测试
  // res.data.dataList[0].local_disk_info.root_fs_total_size = 99999999999999999999
  // res.data.dataList[0].local_disk_info.root_fs_used_size = 999999999999999999999
  tableData.value = res.data.dataList
  tatolPage.value = res.data.totalRecord
  loading.value = false
  polling()
})
onUnmounted(() => {
  clearInterval(inervalTimer as NodeJS.Timeout)
})

function getImages() {
  getAllImages().then((res) => {
    imagesTree.value = setElCascader(res.data)
  })
}
// 设置镜像级联
function setElCascader(tree: imagesCascader & images) {
  if (!tree) {
    return []
  }
  for (let item of tree) {
    item.value = item.image_name
    item.label = item.image_name
    if (item.image_versions) {
      item.children = []
      for (let version of item.image_versions) {
        item.children.push({
          value: version.image_id,
          label: version.version,
          notes: version.notes
        })
      }
    }
  }
  return tree
}
// 轮询
function polling() {
  clearInterval(inervalTimer as NodeJS.Timeout)
  inervalTimer = setInterval(async () => {
    const res = await getDevMachineList_pages({
      page_no: currPage.value,
      page_size: pageSize,
      stop_time_order: currSort.value,
      status: currStatus.value,
      nick_name: searchByNickNameStr.value
    })
    tableData.value = res.data.dataList
    tatolPage.value = res.data.totalRecord
  }, autoPollingTimer.value)
}
// 手动刷新
async function refresh(forOnce: boolean = false) {
  clearInterval(inervalTimer as NodeJS.Timeout)
  let res = await getDevMachineList_pages({
    page_no: currPage.value,
    page_size: pageSize,
    stop_time_order: currSort.value,
    status: currStatus.value,
    nick_name: searchByNickNameStr.value
  })
  tableData.value = res.data.dataList
  tatolPage.value = res.data.totalRecord
  if (forOnce) {
    return
  }
  autoPollingTimer.value = fastTimer
  polling()
  setTimeout(() => {
    autoPollingTimer.value = slowTimer
    polling()
  }, 15000)
}

async function handlePageDown() {
  if (currPage.value == 1) {
    return
  }
  currPage.value--
  refresh(true)
}
function handlePageUp() {
  currPage.value++
  refresh(true)
}
function changePage(page: number) {
  currPage.value = page
  refresh(true)
}
// 开机/关机
async function handleShutdownOrStart(row: Response.Distance.DistanceDataList) {
  if (!getIsAllowStopByAvailableDict(row.status) && !getIsAllowStartByAvailableDict(row.status)) {
    return
  }
  if (row.status == 5) {
    startMechine(row)
  } else {
    stopMechine(row)
  }
}
// 关机
async function stopMechine(row: Response.Distance.DistanceDataList) {
  let res = await stopDevMachine({
    web_ide_instance_id: row.webide_instance_id
  })
  if (res.code == 200) {
    autolog.log('操作成功', 'success')
    refresh()
  }
}
// 开机
async function startMechine(row: Response.Distance.DistanceDataList, startMode: string = 'gpu') {
  let res = await startDevMachine({
    web_ide_instance_id: row.webide_instance_id,
    start_mode: startMode
  })
  if (res.code == 200) {
    autolog.log('操作成功', 'success')
    refresh()
  }
}

function changeChargeType(row: Response.Distance.DistanceDataList) {
  console.log('row::: ', row)
  autolog.log('暂未开放', 'warn')
  console.log('转包年包月')
}

function pullupDialog(row: Response.Distance.DistanceDataList, key: string) {
  currMechineId.value = row.webide_instance_id
  dialogVisibleDict[key] = true
  currentlyOperatingOption.value = key
  if (key == '更换镜像') {
    getImages()
  }
}
function resetSystemDisk() {
  resetDevMachine({
    web_ide_instance_id: currMechineId.value
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
    }
  })
}
function changeSSHPassword() {
  modifyDevMachinePassword({
    web_ide_instance_id: currMechineId.value,
    password: newSSHPassword.value
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
    }
  })
}
function changeInstanceNickName() {
  modifyDevMachineName({
    web_ide_instance_id: currMechineId.value,
    nick_name: newInstanceNickName.value
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
      refresh(true)
      newInstanceNickName.value = ''
    }
  })
}
function changeImage() {
  if (!newImageId.value[1]) {
    autolog.log('请选择镜像', 'warn')
    console.log('请选择镜像')
    return
  }
  modifyDevMachineImage({
    web_ide_instance_id: currMechineId.value,
    image_id: newImageId.value[1]
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
      currImageNotes.value = ''
    }
  })
}
function releaseMechine() {
  if (!confirmedMechineId.value) {
    autolog.log('请输入实例ID', 'warn')
    return
  }
  if (confirmedMechineId.value != currMechine.value?.webide_instance_uuid.slice(0, 5)) {
    autolog.log('实例ID不正确', 'warn')
    return
  }
  releaseDevMachine({
    web_ide_instance_id: currMechineId.value
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
      refresh(true)
      confirmedMechineId.value = ''
    }
  })
}
async function preservationImage() {
  const res = await getDevMachineDetail({
    webide_instance_id: currMechineId.value
  })
  if (!res.data.image_name) {
    return
  }
  saveImage({
    webide_run_id: currMechineId.value,
    image_name: res.data.image_name
  }).then((res) => {
    if (res.code == 200) {
      autolog.log('操作成功', 'success')
      clearDialogVisibleAndClearInputValue()
    }
  })
}

async function getMachineDetail(id: string) {
  deTailLoading.value = true
  currDetail.value = {} as instanceDetail
  const res = await getDevMachineDetail({
    webide_instance_id: id
  })
  currDetail.value = res.data
  deTailLoading.value = false
}
// 跳转租用实例
function goRoute(name: string) {
  router.push({
    name: name
  })
}
// 状态筛选
function filterHandler(value: string, row: Response.Distance.DistanceDataList) {
  return value.includes(row.status.toString())
}
watch(currFilter.value, () => {
  refresh(true)
})
// 镜像级联选择
function handleChangeImages(value: any) {
  for (let item of imagesTree.value) {
    if (item.children) {
      for (let child of item.children) {
        if (child.value == value[value.length - 1]) {
          currImageNotes.value = child.notes!
        }
      }
    }
  }
}
// 扩容
function diskExpansion() {
  autolog.log('暂未开放', 'warn')
  console.log('扩容')
}
// 自定义服务
function customServer() {
  clearDialogVisibleAndClearInputValue()
}

function changeSort(e: { prop: string; order: string }) {
  currSort.value = e.order == 'ascending' ? '1' : '2'
  refresh(true)
}
function diskMinWidth() {
  const maxDiskLength = tableData.value.reduce((prev, curr) => {
    return Math.max(
      prev,
      bytes2GB(curr.local_disk_info.root_fs_total_size).toFixed(2).toString().length + bytes2GB(curr.local_disk_info.root_fs_used_size).toString().length,
      bytes2GB(curr.local_disk_info.data_disk_used_size).toString().length + bytes2GB(curr.local_disk_info.data_disk_total_size).toFixed(2).toString().length
    )
  }, 0)
  return maxDiskLength * 8 + 150
}
function usedDiskMinWidth() {
  const maxDiskLength = tableData.value.reduce((prev, curr) => {
    return Math.max(prev, bytes2GB(curr.local_disk_info.root_fs_used_size).toString().length, bytes2GB(curr.local_disk_info.data_disk_used_size).toString().length)
  }, 0)
  return maxDiskLength * 8
}
function totalDiskMinWidth() {
  const maxDiskLength = tableData.value.reduce((prev, curr) => {
    return Math.max(prev, bytes2GB(curr.local_disk_info.root_fs_total_size).toString().length, bytes2GB(curr.local_disk_info.data_disk_total_size).toString().length)
  }, 0)
  return maxDiskLength * 8
}
function searchKeyDown(e: KeyboardEvent | Event) {
  if (e instanceof KeyboardEvent && e.key == 'Enter') {
    refresh(true)
  }
}
function clearDialogVisibleAndClearInputValue() {
  clearDialogVisible()
  newSSHPassword.value = ''
  newInstanceNickName.value = ''
  newImageId.value = ''
  confirmedMechineId.value = ''
  diskExpansionSize.value = 0
  currImageNotes.value = ''
}
</script>

<template>
  <div class="InstancesComBox">
    <div class="body" :loading="loading">
      <div class="header">
        <span>容器实例</span>
      </div>
      <div class="options">
        <div>
          <el-input v-model="searchByNickNameStr" style="width: 240px" placeholder="容器名称搜索" @keydown="searchKeyDown">
            <template #suffix>
              <span class="iconfont icon-sousuo c:p search" @click="refresh(true)"></span>
            </template>
          </el-input>
        </div>
        <div class="newInstance">
          <span @click="goRoute('密钥管理')">密钥管理</span>
          <span @click="goRoute('算力市场')">租用实例</span>
        </div>
      </div>
      <div class="table">
        <el-table :data="tableData" style="width: 100%" height="100%" header-row-class-name="tableHeader" @sort-change="changeSort">
          <el-table-column prop="webide_instance_uuid" label="实例ID/名称" :min-width="180">
            <template #default="scope">
              <div class="name">
                <div class="edit">
                  <span class="nickName">{{ scope.row.nick_name || '设置名称' }}</span>
                  <span class="iconfont icon-bianji1" @click="pullupDialog(scope.row, '修改实例名称')"></span>
                </div>
                <span class="uuid">{{ scope.row.webide_instance_uuid }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            :filters="[
              { text: '运行中', value: '3' },
              { text: '已关机', value: '5' },
              { text: '异常', value: '7' },
              { text: '其他', value: '0124689' }
            ]"
            :filtered-value="currFilter"
            :filter-multiple="false"
            :filter-method="filterHandler"
            filter-class-name="statusFilter"
            :min-width="100">
            <template #default="scope">
              <div class="status" :style="{ color: statusDict[scope.row.status]?.color }">
                <span
                  class="iconfont"
                  :class="statusDict[scope.row.status]?.icon"
                  :style="{ backgroundColor: statusDict[scope.row.status]?.icon == 'icon-dot' ? statusDict[scope.row.status]?.color : '' }"></span>
                <div class="statusText">
                  <span> {{ statusDict[scope.row.status]?.label }}</span>
                  <span class="noGpuMode" v-if="scope.row.status == 3"> {{ scope.row.start_mode == 'gpu' ? '' : '(无卡模式)' }}</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="spec_desc" label="规格详情" :min-width="120">
            <template #default="scope">
              <div class="spec_info">
                <div>
                  <span>{{ scope.row.product_name }}</span> <span>* {{ scope.row.req_gpu_amount }} 卡</span>
                </div>
                <div class="viewDetail">
                  <el-popover ref="popoverRef" trigger="click" width="max-content">
                    <template #reference>
                      <span @mouseenter="getMachineDetail(scope.row.webide_instance_id)">详情</span>
                    </template>
                    <div class="detail" v-if="currDetail.product_name">
                      <div>
                        <span>产品名称：</span><span>{{ currDetail.product_name }} * {{ scope.row.req_gpu_amount }}</span>
                      </div>
                      <div v-for="(item, key) in table.machineDetailDict">
                        <span> {{ item.label }}：</span>
                        <span v-if="item.label == 'CPU'">{{ item.type == 'GB' ? bytes2GB(currDetail[key]) + ' GB' : currDetail[key] }} 核 , {{ currDetail['cpu_arch'] }}</span>
                        <span v-else> {{ item.type == 'GB' ? bytes2GB(currDetail[key]) + ' GB' : currDetail[key] }}</span>
                      </div>
                    </div>
                  </el-popover>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="local_disk_info" label="存储" :min-width="diskMinWidth()">
            <template #default="scope">
              <div class="storage">
                <div class="diskInfo">
                  <div class="sysDisk disk" :style="`min-width:${diskMinWidth() - 60}px`">
                    <span>系统盘:</span>
                    <span :style="`min-width:${usedDiskMinWidth()}px`">{{ bytes2GB(scope.row.local_disk_info.root_fs_used_size) }} </span>
                    <span> / </span>
                    <span :style="`min-width:${totalDiskMinWidth()}px`">{{ bytes2GB(scope.row.local_disk_info.root_fs_total_size) }} </span>
                    <span>GB</span>
                  </div>
                  <div class="dataDisk disk" :style="`min-width:${diskMinWidth() - 60}px`">
                    <span>数据盘:</span>
                    <span :style="`min-width:${usedDiskMinWidth()}px`">{{ bytes2GB(scope.row.local_disk_info.data_disk_used_size) }} </span>
                    <span> / </span>
                    <span :style="`min-width:${totalDiskMinWidth()}px`">{{ bytes2GB(scope.row.local_disk_info.data_disk_total_size) }}</span>
                    <span>GB</span>
                  </div>
                </div>
                <div class="more" @click="pullupDialog(scope.row, '扩容')">
                  <span>扩容</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="charge_type" label="付费方式" :min-width="100" align="center">
            <template #default="scope">
              <div class="chargeType">
                <span>{{ table.chargeTypeDict[scope.row.charge_type] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="release_time" label="释放/到期时间" :min-width="180" align="center" sortable>
            <template #default="scope">
              <div class="chargeType">
                <span v-if="scope.row.status == 5"> {{ moment(scope.row.release_time).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ssh_command" label="SSH" :min-width="120" align="center">
            <template #default="scope">
              <div class="ssh" v-if="scope.row.status == 3">
                <div @click="copyInnerText(scope.row.ssh_command)" class="copyBtn">
                  <div class="copyBox">
                    <span>登录指令</span>
                  </div>
                  <span class="iconfont icon-fuzhi"></span>
                </div>
                <div @click="copyInnerText(scope.row.ssh_password)" class="copyBtn">
                  <div class="copyBox">
                    <span>密码</span>
                  </div>
                  <span class="iconfont icon-fuzhi"></span>
                </div>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="jupyter_url" label="快捷工具" :min-width="100" align="center">
            <template #default="scope">
              <div class="toolsLink" v-if="scope.row.status == 3">
                <a
                  :href="scope.row.status == 3 ? scope.row.jupyter_url : '#'"
                  :target="`${scope.row.status == 3 ? '_blank' : ''}`"
                  :style="`color:${scope.row.status == 3 ? 'var(--theme-main-color-1)' : '#cccccc'}`"
                  >JupyterLab</a
                >
                <span @click="pullupDialog(scope.row, '自定义服务')">自定义服务</span>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="webide_instance_id" label="操作" :min-width="120" align="center" s>
            <template #default="scope">
              <div class="options">
                <span
                  size="small"
                  @click="handleShutdownOrStart(scope.row)"
                  :class="{ disable: scope.row.status != 5 ? !getIsAllowStopByAvailableDict(scope.row.status) : !getIsAllowStartByAvailableDict(scope.row.status) }">
                  {{ scope.row.status != 5 ? '关机' : '开机' }}
                </span>
                <el-dropdown trigger="click" class="manage">
                  <div>
                    <span class="el-dropdown-link"> 更多 </span>
                    <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon>
                  </div>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item @click="startMechine(scope.row, 'nogpu')" :disabled="getIsDisable(scope.row.status, '无卡模式开机')">无卡模式开机</el-dropdown-item>
                      <el-dropdown-item @click="pullupDialog(scope.row, '更换镜像')" :disabled="getIsDisable(scope.row.status, '更换镜像')">更换镜像</el-dropdown-item>
                      <el-dropdown-item @click="changeChargeType(scope.row)" :disabled="getIsDisable(scope.row.status, '转包年包月')">转包年包月</el-dropdown-item>
                      <el-dropdown-item @click="pullupDialog(scope.row, '修改SSH密码')">修改SSH密码</el-dropdown-item>
                      <el-dropdown-item @click="pullupDialog(scope.row, '重置系统')" :disabled="getIsDisable(scope.row.status, '重置系统')">重置系统</el-dropdown-item>
                      <el-dropdown-item @click="pullupDialog(scope.row, '保存镜像')" :disabled="getIsDisable(scope.row.status, '保存镜像')" v-if="false">保存镜像</el-dropdown-item>
                      <el-dropdown-item @click="pullupDialog(scope.row, '释放实例')" :disabled="getIsDisable(scope.row.status, '释放实例')">释放实例</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="tatolPage" @prev-click="handlePageDown" @next-click="handlePageUp" @change="changePage" />
      </div>
    </div>
  </div>
  <ConfirmDialog title="修改SSH登录密码" v-if="dialogVisibleDict.修改SSH密码" @cancel="clearDialogVisibleAndClearInputValue" @confirm="changeSSHPassword">
    <div class="tip warn">
      <span class="iconfont icon-tishi"></span>
      <span>修改SSH登录密码需要重启后生效</span>
    </div>
    <div class="tip warn">
      <span class="iconfont icon-tishi"></span>
      <div>修改后无法撤销，请谨慎操作</div>
    </div>
    <el-input placeholder="请输入新密码" type="password" v-model="newSSHPassword"></el-input>
  </ConfirmDialog>
  <ConfirmDialog title="修改实例名称" v-if="dialogVisibleDict.修改实例名称" @cancel="clearDialogVisibleAndClearInputValue" @confirm="changeInstanceNickName">
    <el-input placeholder="请输入新名称" v-model="newInstanceNickName"></el-input>
  </ConfirmDialog>
  <ConfirmDialog title="重置系统" v-if="dialogVisibleDict.重置系统" @cancel="clearDialogVisibleAndClearInputValue" @confirm="resetSystemDisk">
    <div class="tip warn">
      <span class="iconfont icon-tishi"></span>
      <span>重置后无法撤销，请谨慎操作</span>
    </div>
  </ConfirmDialog>
  <ConfirmDialog title="更换镜像" v-if="dialogVisibleDict.更换镜像" @cancel="clearDialogVisibleAndClearInputValue" @confirm="changeImage">
    <el-cascader v-model="newImageId" :options="imagesTree" @change="handleChangeImages" placeholder="请选择镜像"> </el-cascader>
    <span class="currImageNotes">{{ currImageNotes }}</span>
  </ConfirmDialog>
  <ConfirmDialog title="保存镜像" v-if="dialogVisibleDict.保存镜像" @cancel="clearDialogVisibleAndClearInputValue" @confirm="preservationImage">
    <span>您要保存镜像吗？</span>
  </ConfirmDialog>
  <ConfirmDialog title="释放实例" titleIcon="icon-tishi" icon-color="#c00b22" v-if="dialogVisibleDict.释放实例" @cancel="clearDialogVisibleAndClearInputValue" @confirm="releaseMechine">
    <div class="paddle26 d:f fdc gap10px paddb10">
      <div>确认释放实例 {{ currMechine?.nick_name }} {{ currMechine?.webide_instance_uuid }} 吗？</div>
      <div>实例系统盘、数据盘将无法恢复，共享文件不受影响。</div>
      <div>
        确认释放请输入: <span class="padd4 pale12 paddr12 bg#e3f6ff colo#1992ff brrr6px">{{ currMechine?.webide_instance_uuid.slice(0, 5) }}</span>
      </div>
    </div>
    <el-input class="paddle26" placeholder="请您请输入实例 ID 前五位以确认释放操作" v-model="confirmedMechineId"></el-input>
  </ConfirmDialog>
  <ConfirmDialog title="扩容数据盘" v-if="dialogVisibleDict.扩容" @cancel="clearDialogVisibleAndClearInputValue" @confirm="diskExpansion">
    <div class="warn diskExpansion">
      <span class="iconfont icon-tishi"></span>
      <div>扩容当日，会按照最大容量进行收费，具体收费规则请查看<a href="#">这里</a>。</div>
    </div>
    <div class="options diskExpansion">
      <div class="optionItem">
        <span>扩容磁盘：</span>
        <div>
          <span>免费容量</span>
          <span>+</span>
          <el-input-number style="width: 100px" size="small" placeholder="请输入扩容容量" v-model="diskExpansionSize" type="number" controls-position="right" :min="0" :max="100">
            <template #suffix>
              <span>GB</span>
            </template>
          </el-input-number>
          <span>=</span>
          <span class="size">{{ diskExpansionSize + 50 }}</span>
          <span>GB</span>
        </div>
      </div>
      <div class="optionItem">
        <span>磁盘费用：</span>
        <span class="price unit">￥</span>
        <span class="price">{{ (diskExpansionSize * 0.01).toFixed(2) }}</span>
        <span>/天</span>
        <span class="iconfont icon-tanhao1"></span>
      </div>
    </div>
  </ConfirmDialog>
  <ConfirmDialog title="自定义服务" v-if="dialogVisibleDict.自定义服务" @cancel="clearDialogVisibleAndClearInputValue" @confirm="customServer">
    <div class="customServer">
      <div class="tip">为符合监管需求，暂不开放公开http/https服务，您可以通过一下以下方式访问您的自定义服务：</div>
      <div class="codeArea">
        <div class="header">
          <span>在终端输入命令：</span>
          <span class="iconfont icon-fuzhi" @click="copyInnerText(currMechine?.custom_command)">复制</span>
        </div>
        <div class="code">{{ currMechine?.custom_command }}</div>
      </div>
      <div class="password">如询问Yes/No请回答Yes，并输入一下密码（<span @click="copyInnerText(currMechine?.ssh_password)">复制密码</span>）</div>
      <div class="tip">输入密码回车后无任何其他输出则为正常。请不要关闭终端，否则SSH通道会断开。</div>
      <div class="tip">如显示Permission denied则可能密码粘贴失败，请手动输入密码（Win10终端易出现无法粘贴密码问题）。</div>
      <div class="tip">完成后，您可以在浏览器直接访问：</div>
      <div class="codeArea">
        <div class="header">
          <span>后端URL：</span>
          <span class="iconfont icon-fuzhi" @click="copyInnerText(currMechine?.custom_url)">复制</span>
        </div>
        <div class="code">{{ currMechine?.custom_url }}</div>
      </div>
    </div>
  </ConfirmDialog>
</template>

<style lang="less" scoped>
.InstancesComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .body {
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .header {
      color: #4e5969;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      > span {
        font-weight: bold;
      }
    }

    .options {
      display: flex;
      justify-content: space-between !important;
      width: 100%;
      padding-bottom: 20px;
      .newInstance {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          text-align: center;
          cursor: pointer;
          padding: 4px 8px;
          font-size: 12px;
          background: var(--theme-main-color-1);
          border-radius: 2px;
        }
      }
      .search {
        color: #4e5969;
        cursor: pointer;
        &:hover {
          color: var(--theme-main-color-1);
        }
      }
      span {
        white-space: nowrap;
      }
      .disable {
        color: #ccc !important;
        cursor: not-allowed !important;
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    .table {
      height: 0;
      flex: 1;
      display: flex;
      > div {
        width: 0 !important;
        flex: 1;
      }
      .name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        font-size: 14px;
        color: #151515dc;
        .uuid {
          font-size: 12px;
          color: #9c9c9c;
        }
        .edit {
          padding-top: 12px;
          color: var(--theme-main-color-1);
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 6px;

          .nickName {
            color: #151515;
            font-size: 14px;
          }
        }
      }

      .spec_info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .viewDetail {
          cursor: pointer;
          color: var(--theme-main-color-1);
        }
      }

      .status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;

        .statusText {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .noGpuMode {
            color: #ff4242;
          }
        }

        .iconfont {
          font-size: 16px;
          margin-right: 6px;

          &.icon-jiazai {
            animation: rotate 3s infinite linear;
          }

          &.icon-dot {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 12px;
          }
        }
      }

      .chargeType {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
      }

      .storage {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 5px;

        &:hover .more {
          color: var(--theme-main-color-1);
          cursor: pointer;
          // opacity: 1;
        }

        .more {
          color: var(--theme-main-color-1);
          cursor: pointer;
          white-space: nowrap;
          // opacity: 0;
        }

        .diskInfo {
          display: flex;
          flex-direction: column;
          gap: 6px;
          white-space: nowrap;

          .disk {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 6px;

            span:nth-child(1),
            span:nth-child(2) {
              font-size: 14px;
              color: #4e5969;
            }
          }
        }
      }

      .ssh {
        display: flex;
        flex-direction: column;
        white-space: nowrap;

        .copyBox {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;

          .iconfont {
            font-size: 16px;
            color: var(--theme-main-color-1);
          }
        }

        .copyBtn {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          gap: 10px;
          cursor: pointer;
          color: #151515;

          .iconfont {
            font-size: 16px;
            color: var(--theme-main-color-1);
          }
        }
      }

      .toolsLink {
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        gap: 6px;
        a,
        span {
          color: var(--theme-main-color-1);
          cursor: pointer;
        }
      }

      .options {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        span {
          color: var(--theme-main-color-1);
          cursor: pointer;
          line-height: 20px;
          font-size: 14px;
        }

        .manage {
          margin-left: 10px;
          cursor: pointer;

          :deep(.el-icon.el-icon--right) {
            margin: 0;
          }

          div {
            color: var(--theme-main-color-1);
            padding: 2px 8px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.cascaderItem {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  gap: 2px;

  span {
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:nth-child(2) {
    color: #9c9c9c;
    font-weight: normal;
    font-size: 12px;
  }
}

.currImageNotes {
  font-size: 14px;
  color: #4e5969;
  position: absolute;
  top: 100%;
  left: 10px;
  padding-top: 10px;
  color: #9c9c9c;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding-right: 40px;

  > div {
    display: flex;
    gap: 8px;

    &:nth-child(1) {
      grid-column: span 2;
    }

    &:last-child {
      grid-column: span 2;
    }

    span:nth-child(1) {
      color: #4e5969;
      display: inline-block;
      width: 110px;
      white-space: nowrap;
      text-align: right;
    }

    span:nth-child(2) {
      color: #151515;
    }
  }
}
</style>
<style lang="less">
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.statusFilter {
  .is-active {
    color: var(--theme-main-color);
    background: #fff;
  }
  .el-table-filter__list-item:hover {
    background: #fff;
  }
}
.warn.diskExpansion {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1d2129;
  background: rgba(241, 146, 0, 0.1);
  border: 1px solid #f5cb8c;
  padding: 10px;
  font-size: 14px;
  margin-bottom: 20px;
  border-radius: 3px;
  .iconfont {
    font-size: 16px;
    color: #f19200;
  }
  a {
    color: var(--theme-main-color-1);
  }
}
.options.diskExpansion {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .optionItem {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    line-height: 1;
    .icon-tanhao1 {
      color: #9c9c9c;
      cursor: pointer;
    }
    &:first-child {
      align-items: center;
    }
    .price {
      color: var(--theme-main-color);
      font-size: 24px;
      &.unit {
        font-size: 12px;
      }
    }
    .size {
      font-weight: 500;
      font-size: 16px;
      color: #151515;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 15px;
      white-space: nowrap;
    }
    > span {
      color: #1d2129;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 5px;
      &:first-child {
        color: #9c9c9c;
      }
      .iconfont {
        font-size: 16px;
        color: #f19200;
      }
    }
  }
}
.customServer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  width: 470px;
  .tip {
    color: #4e5969;
    font-size: 12px;
    .icon-tanhao1 {
      color: #9c9c9c;
      cursor: pointer;
    }
  }
  .codeArea {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #d1d9e0;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      padding: 6px 16px;
      .icon-fuzhi {
        color: var(--theme-main-color-1);
        cursor: pointer;
        font-size: 12px;
        &::before {
          padding-right: 5px;
        }
      }
    }
    .code {
      font-size: 14px;
      color: #151515;
      background: #f6f8fa;
      padding: 10px 16px;
      padding-bottom: 34px;
    }
  }
  .password {
    color: #151515;
    font-size: 12px;
    span {
      color: var(--theme-main-color-1);
      cursor: pointer;
    }
  }
}
</style>
