import { get } from '@/utils/request/request'

// 首页热门产品
export const getProdHot = async () => {
  return await get('/api/v1/products/hot')
}

// 地区字典
export const getRegionList = async () => {
  return await get('/api/v1/region/tag')
}
export const getDatacenterList = async () => {
  return await get('/api/v1/data_center/tag')
}
export const getProductList_gpuTypes = async (params: { page: number; pageSize: number; region: string; tag: string }) => {
  return await get('/api/v1/products/gpu_types', params)
}
export const getProductNameList = async () => {
  return await get('/api/v1/products/listGpuNames', {})
}
export const getProductList = async (params?: { data_center_id?: string; gpu_request_amount?: string; product_names?: string }) => {
  return await get('/api/v1/products/list', params)
}
