<script setup lang="ts">
import { str2Asterisk } from '@/utils'
import Cookies from 'js-cookie'
import { ref } from 'vue'
import eventBus from '@/utils/eventBus'
import autolog from 'autolog.js'
import ConfirmDialog from '../InstancesCom/ConfirmDialog/ConfirmDialog.vue'

const isShowChangePassword = ref(false)
const isShowChangePhone = ref(false)
const isShowRealNameAuthentication = ref(false)
const isShowBindWechat = ref(false)
const changePasswordFormRef = ref()

const changePasswordForm = ref({
  newPassword: '',
  confirmPassword: ''
})
const changePasswordForm_rules = ref({
  newPassword: [{ required: true, message: '请输入新密码', validator: validatePass, trigger: 'blur' }],
  confirmPassword: [{ required: true, message: '两次密码输入不一致', validator: validatePass2, trigger: 'blur' }]
})

const changePhoneForm = ref({
  newPhoneNumber: '',
  vCode: ''
})
const changePhoneForm_rules = ref({
  newPassword: [{ required: true, message: '请输入新手机号', trigger: 'blur' }],
  vCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})

function validatePass(_: string, value: string, callback: Function) {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    callback()
  }
}
function validatePass2(_: string, value: string, callback: Function) {
  if (value === '') {
    callback(new Error('请再次输入密码'))
  } else if (value !== changePasswordForm.value.newPassword) {
    callback(new Error('两次输入密码不一致'))
  } else {
    callback()
  }
}
const userInfos = ref<{
  [key: string]: {
    label: string
    value: string | boolean
    allowEdit: boolean
    handleText: string[]
    statusText: string[]
    handle?: () => void
  }
}>({
  username: {
    label: '用户名',
    value: Cookies.get('username') || '',
    allowEdit: false,
    handleText: [],
    statusText: []
  },
  password: {
    label: '密码',
    value: false,
    allowEdit: true,
    handleText: ['修改', '修改'],
    statusText: ['未设置', '已设置'],
    handle: changePassword
  },
  phone: {
    label: '手机号',
    value: str2Asterisk(Cookies.get('username')!, 3, 4),
    allowEdit: true,
    handleText: ['修改', '修改'],
    statusText: [],
    handle: changePhone
  },
  realNameAuthentication: {
    label: '实名认证',
    value: false,
    allowEdit: true,
    handleText: ['认证', '修改认证信息'],
    statusText: ['未认证', '已认证'],
    handle: realNameAuthentication
  },
  wxpay: {
    label: '绑定微信',
    value: false,
    allowEdit: true,
    handleText: ['绑定', '更换绑定'],
    statusText: ['未绑定', '已绑定'],
    handle: bindWechat
  }
})
function changePassword() {
  isShowChangePassword.value = true
}
function changePhone() {
  eventBus.emit('showUnifiedVerificationModule')
}
function realNameAuthentication() {
  isShowRealNameAuthentication.value = true
}
function bindWechat() {
  isShowBindWechat.value = true
}
function confirmChangePassword() {
  changePasswordFormRef.value.validate((valid: boolean) => {
    if (valid) {
      console.log('submit!')
    } else {
      console.log('error submit!')
    }
  })
}
function verifySuccess() {
  autolog.log('验证成功', 'success')
  console.log('verifySuccess')
  isShowChangePhone.value = true
}
function verifyFail() {
  autolog.log('验证码错误', 'error')
  console.log('verifyFail')
}
function verifyCancel() {
  console.log('verifyCancel')
}
</script>

<template>
  <div class="UserInfoComBox">
    <div class="body">
      <div class="header">个人中心</div>
      <div class="infos">
        <div class="infoItem" v-for="item in userInfos">
          <div class="label">{{ item.label }}：</div>
          <div class="value" :style="`color:${item.label == '实名认证' || item.label == '绑定微信' ? `${!item.value ? '#f19200' : '#86c13d'}` : ''}`">
            <span
              class="iconfont"
              v-if="item.statusText[1] && item.label != '密码'"
              :class="item.value ? 'icon-yiwancheng' : 'icon-weiwancheng'"
              :style="`color:${item.value ? '#86c13d' : '#f19200'}`"></span>
            {{ item.value === true ? item.statusText[1] : item.statusText[0] || item.value }}
          </div>
          <div class="handle" v-if="item.allowEdit" @click="item.handle">
            <span>{{ item.value ? item.handleText[1] : item.handleText[0] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog v-if="isShowChangePassword" title="重置密码" @cancel="isShowChangePassword = false" @confirm="confirmChangePassword">
    <div class="mdfPassword">
      <el-form ref="changePasswordFormRef" :model="changePasswordForm" :rules="changePasswordForm_rules" label-width="100px">
        <el-form-item label="输入新密码" prop="newPassword">
          <el-input v-model="changePasswordForm.newPassword" show-password />
        </el-form-item>
        <el-form-item label="再次输入密码" prop="confirmPassword">
          <el-input v-model="changePasswordForm.confirmPassword" show-password />
        </el-form-item>
      </el-form>
    </div>
  </ConfirmDialog>
  <ConfirmDialog v-if="isShowChangePhone" title="修改手机号" @cancel="isShowChangePhone = false" @confirm="isShowChangePhone = false">
    <div class="changePhone">
      <el-form ref="changePhoneFormRef" :model="changePhoneForm" :rules="changePhoneForm_rules">
        <el-form-item label="新手机号" prop="newPhoneNumber">
          <el-input v-model="changePhoneForm.newPhoneNumber" show-password />
        </el-form-item>
        <el-form-item label="验证码" prop="vCode">
          <div class="vCode">
            <el-input v-model="changePhoneForm.vCode" />
            <span class="send">发送验证码</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </ConfirmDialog>
  <ConfirmDialog v-if="isShowRealNameAuthentication" title="实名认证" @cancel="isShowRealNameAuthentication = false" @confirm="isShowRealNameAuthentication = false">
    <div class="changePhoneNumber">
      <el-form ref="changePhoneFormRef" :model="changePhoneForm" :rules="changePhoneForm_rules">
        <el-form-item label="姓名" prop="newPhoneNumber">
          <el-input v-model="changePhoneForm.newPhoneNumber" show-password />
        </el-form-item>
        <el-form-item label="身份证号" prop="vCode">
          <div class="vCode">
            <el-input v-model="changePhoneForm.vCode" />
            <span class="send">发送验证码</span>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </ConfirmDialog>
  <ConfirmDialog v-if="isShowBindWechat" title="绑定微信" @cancel="isShowBindWechat = false" @confirm="isShowBindWechat = false">
    <div class="changePasword">
      <el-form ref="changePasswordFormRef" :model="changePasswordForm" :rules="changePasswordForm_rules" label-width="100px">
        <el-form-item label="输入新密码" prop="newPassword">
          <el-input v-model="changePasswordForm.newPassword" show-password />
        </el-form-item>
        <el-form-item label="再次输入密码" prop="confirmPassword">
          <el-input v-model="changePasswordForm.confirmPassword" show-password />
        </el-form-item>
      </el-form>
    </div>
  </ConfirmDialog>
  <UnifiedVerificationModule @verify-success="verifySuccess" @verify-fail="verifyFail" @verify-cancel="verifyCancel" />
</template>

<style lang="less" scoped>
.UserInfoComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .body {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #ebebeb;
    padding: 22px;

    .header {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #4e5969;
    }

    .infos {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-top: 50px;
      gap: 30px;

      .infoItem {
        display: flex;
        justify-content: flex-start;
        gap: 16px;

        .label {
          min-width: 138px;
          font-size: 14px;
          color: #4e5969;
          text-align: right;
        }

        .value {
          min-width: 100px;
          font-weight: normal;
          color: #151515;
          font-size: 14px;
          text-align: left;
        }

        .handle {
          cursor: pointer;
          color: #4273f8;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.mdfPassword,
.changePhone {
  padding-right: 40px;

  .vCode {
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;

    span.send {
      cursor: pointer;
      color: var(--theme-main-color);
      margin-left: 10px;
      border: 1px solid var(--theme-main-color);
      padding: 0px 10px;
      border-radius: 4px;
    }
  }
}

.changePhoneNumber,
.changePasword {
  .Confirm {
    cursor: pointer;
    padding: 8px 46px;
    border-radius: 4px;
    color: #fff;
    background: var(--theme-main-color);
    width: 80%;
    display: inline-block;
    margin: 0 30px;
  }

  .el-dialog__header.show-close {
    padding-bottom: 36px;
    padding-top: 34px;
  }

  .el-dialog__footer {
    padding-bottom: 32px;
    padding-top: 24px;

    .cancel,
    .confirm {
      cursor: pointer;
      padding: 8px 46px;
      border-radius: 4px;
      color: #fff;
    }

    .cancel {
      background: #e5e7eb;
      margin-right: 20px;
      color: #4e5969;
    }

    .confirm {
      background: var(--theme-main-color);
    }
  }
}
</style>
