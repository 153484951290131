import { get, post } from '@/utils/request/request'

// 余额明细
export const getBalance = (params: API.Balance) => get('/api/v1/billing/public/balance/records', params)

// 余额查询
export const getBalanceQuery = () => get('/api/v1/billing/public/balance')

//  日结账单查询
export const getDailyBill = (params: API.DailyBill) => get('/api/v1/billing/public/daily-bill/records', params)

// 流水账单查询
export const getFlowBill = (params: API.FlowBill) => get('/api/v1/billing/public/transaction/records', params)

// 历史订单查询
export const getHistoryOrder = (params: API.OrderHistory) => get<Response.OrderHistory.DefOrderRecords[]>('/api/v1/billing/public/order/records', params)

// 充值
export const recharge = (params: API.Recharge) => post('/api/v1/billing/wxpay/native/order', params)
