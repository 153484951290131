<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElTabs, ElTabPane, ElTable, ElTableColumn, ElPagination, ElDatePicker } from 'element-plus'
import moment from 'moment'
import { getDailyBill, getFlowBill } from '@/apis/bills'
import autolog from 'autolog.js'

const size = 10

const activeName = ref('日结账单')
const dailyDate = ref(['', ''])
const flowDate = ref(['', ''])
const page = ref(1)
const sort = ref<'asc' | 'desc'>('asc')
const sortby = ref('time')
const tatolPage = ref(0)
const dailyStartDate = ref('')
const dailyEndDate = ref('')
const flowStartDate = ref('')
const flowEndDate = ref('')
const tableData_daily = ref()
const tableData_flow = ref()
const typeColor: {
  [key: string]: {
    color: string
    symbol: string
  }
} = {
  充值: {
    color: '#67C23A',
    symbol: '+'
  },
  消费: {
    color: '#F56C6C',
    symbol: '-'
  },
  退款: {
    color: '#67C23A',
    symbol: '+'
  }
}

onMounted(() => {
  getDate()
})

function getDate() {
  if (activeName.value === '日结账单') {
    getDailyBillData()
  } else if (activeName.value === '流水账单') {
    getFlowBillData()
  } else {
    autolog.log('未知错误', 'error')
  }
}

function tabChange() {
  clearDate()
  getDate()
}

function clearDate() {
  dailyDate.value = ['', '']
  flowDate.value = ['', '']
  dailyStartDate.value = ''
  dailyEndDate.value = ''
  flowStartDate.value = ''
  flowEndDate.value = ''
}

function getDailyBillData() {
  getDailyBill({
    page: page.value,
    size: size,
    start: dailyStartDate.value,
    end: dailyEndDate.value,
    sort: sort.value,
    sortby: sortby.value
  }).then((res) => {
    tableData_daily.value = res.data
    tatolPage.value = res.total
  })
}

function getFlowBillData() {
  getFlowBill({
    page: page.value,
    size: size,
    start: flowStartDate.value,
    end: flowEndDate.value,
    sort: sort.value,
    sortby: sortby.value
  }).then((res) => {
    tableData_flow.value = res.data
    tatolPage.value = res.total
  })
}

function handlePageDown() {
  page.value--
  getDate()
}
function handlePageUp() {
  page.value++
  getDate()
}
function changePage(clickPage: number) {
  page.value = clickPage
  getDate()
}

function dailyDateChange(val: string[]) {
  dailyStartDate.value = val[0]
  dailyEndDate.value = val[1]
  getDailyBillData()
}
function flowDateChange(val: string[]) {
  flowStartDate.value = val[0]
  flowEndDate.value = val[1]
  getFlowBillData()
}
</script>

<template>
  <div class="BillsHistoryComBox">
    <div class="body">
      <div class="header">账单历史</div>
      <div class="dataTable">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-change="tabChange">
          <el-tab-pane label="日结账单" name="日结账单">
            <div class="options">
              <div class="datePicker">
                <span>变动查询</span>
                <el-date-picker v-model="dailyDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="default" @change="dailyDateChange" />
              </div>
            </div>
            <el-table :data="tableData_daily" style="width: 100%" header-row-class-name="tableHeader">
              <el-table-column prop="date" label="日期">
                <template #default="{ row }">
                  <span>{{ moment(row.date).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="transaction_type" label="交易类型">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ row.transaction_type }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="交易金额(￥)">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ typeColor[row.transaction_type]?.symbol }}{{ row.amount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="discount_amount" label="优惠金额(￥)"> </el-table-column>
              <el-table-column prop="deduction_amount" label="抵扣金额(￥)"> </el-table-column>
              <el-table-column prop="paid_amount" label="支付额(￥)">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[`消费`].color }">{{ row.paid_amount }}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="流水账单" name="流水账单">
            <div class="options">
              <div class="datePicker">
                <span>变动查询</span>
                <el-date-picker v-model="flowDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="default" @change="flowDateChange" />
              </div>
            </div>
            <el-table :data="tableData_flow" style="width: 100%" header-row-class-name="tableHeader">
              <el-table-column prop="date" label="日期">
                <template #default="{ row }">
                  <span>{{ moment(row.date).format('YYYY-MM-DD HH:mm:ss') }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="transaction_id" label="交易ID"> </el-table-column>
              <el-table-column prop="transaction_type" label="交易类型">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ row.transaction_type }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="product_name" label="产品类型"> </el-table-column>
              <el-table-column prop="amount" label="交易金额(￥)">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ typeColor[row.transaction_type]?.symbol }}{{ row.amount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="deduction_amount" label="优惠金额(￥)"> </el-table-column>
              <el-table-column prop="paid_amount" label="支付额(￥)">
                <template #default="{ row }">
                  <span :style="{ color: typeColor[`消费`].color }">{{ row.paid_amount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="order_id" label="订单ID"> </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="pages">
        <el-pagination background layout="prev, pager, next" :total="tatolPage" @prev-click="handlePageDown" @next-click="handlePageUp" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.BillsHistoryComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .body {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;

    .pages {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .dataTable {
      flex: 1;
    }

    .header {
      font-size: 16px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-bottom: 34px;
    }

    .datePicker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 26px;
      padding-top: 20px;
      gap: 20px;
      width: max-content;

      span {
        font-size: 14px;
        color: #4e5969;
      }
    }
  }
}
</style>
<style>
.el-tabs__active-bar.is-top {
  height: 4px;
}
</style>
