<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { getProdHot } from '@/apis/prodData'
import { bytes2GB } from '@/utils'
import router from '@/router'

const recommendList = ref<products>([])
const loading = ref(true)

onMounted(() => {
  getProdHot().then((res) => {
    recommendList.value = res.data
    setTimeout(() => {
      loading.value = false
    }, 1000)
  })
})
function goMarketAndCheck(item: ArrayItem<products>) {
  router.push({
    name: '算力市场',
    query: {
      product_id: item.product_id,
      product_name: item.product_name
    }
  })
}
</script>

<template>
  <div class="RecommendBox">
    <div class="mainTitle">热门算力显卡</div>
    <div class="subTitle"></div>
    <div class="recommendGrid" :loading="loading">
      <div class="recommendItem" v-for="item in recommendList">
        <div class="infos">
          <div class="product_name">
            {{ item.product_name }}
          </div>
          <div class="other">
            <div class="cpu_per_gpu">
              <span>CPU:</span>
              <span class="number">
                {{ item.cpu_per_gpu }}
              </span>
              核 起
            </div>
            <div class="cpu_per_gpu">
              <span>数据盘:</span>
              <span class="number">
                {{ bytes2GB(item.data_disk_size) }}
              </span>
              GB 起
            </div>
            <div class="mem_per_gpu">
              <span>内存:</span>
              <span class="number">
                {{ bytes2GB(item.mem_per_gpu) }}
              </span>
              GB 起
            </div>
            <div class="sys_disk_size">
              <span>系统盘:</span>
              <span class="number">
                {{ bytes2GB(item.sys_disk_size) }}
              </span>
              GB 起
            </div>
          </div>
        </div>
        <div class="current_price">
          <div>
            <span class="number">
              <span class="CNY">￥</span>
              {{ (item.current_price / 100).toFixed(2) }}
            </span>
            <span class="priceUnit"> 起 / 时 </span>
          </div>
          <div class="getNow" @click="goMarketAndCheck(item)">立即租用</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.RecommendBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mainTitle {
    font-size: 36px;
    font-weight: bold;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .subTitle {
    color: #707984;
    max-width: 665px;
    font-size: 16px;
    text-align: center;
    line-height: 30px;

    a {
      color: var(--theme-active-color);
      text-decoration: none;
    }
  }

  .recommendGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    max-width: 1200px;

    .recommendItem {
      background: #fffbf5;
      box-shadow: 0px 2px 4px 0px rgba(66, 83, 124, 0.15);
      border-radius: 6px;
      border: 1px solid #e5e7eb;
      padding: 18px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 100px;
      .infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        font-weight: 500;

        .other {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          justify-content: space-between;
          column-gap: 75px;
        }
      }

      .product_name {
        font-size: 18px;
        color: #000000;
        padding-bottom: 20px;
      }

      .cpu_per_gpu,
      .mem_per_gpu,
      .sys_disk_size {
        &.cpu_per_gpu {
          padding-bottom: 10px;
        }

        font-size: 14px;
        color: #000000;

        .number {
          font-weight: bold;
          color: #000000;
          padding-right: 0;
        }

        span {
          color: #707984;
          padding-right: 20px;
        }
      }

      .current_price {
        font-size: 24px;
        font-weight: bold;
        color: var(--theme-active-color);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-left: 72px;
        height: 100%;

        .CNY {
          font-size: 16px;
          font-weight: normal;
          color: #333;
        }

        .priceUnit {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
        }

        .getNow {
          height: 28px;
          width: 83px;
          background: var(--theme-active-color);
          border-radius: 4px;
          border: 1px solid var(--theme-active-color);
          color: #fff;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-weight: normal;

          &:hover {
            background: var(--theme-active-color);
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
