import { get } from '@/utils/request/request'

// 获取实例看板数据
export const getInstanceIndex = async () => {
  return await get('/api/v1/dashboard/res/instance')
}
// 获取存储看板数据
export const getStorageIndex = async () => {
  return await get('/api/v1/dashboard/res/storage')
}
