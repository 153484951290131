<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElDatePicker, ElTable, ElTableColumn, ElPagination } from 'element-plus'
import moment from 'moment'
import { getHistoryOrder } from '@/apis/bills'

const size = 10

const date = ref('')
const page = ref(1)
const startTime = ref('')
const endTime = ref('')
const sort = ref<'asc' | 'desc'>('asc')
const sortby = ref('time')
const tatolPage = ref(0)
const tableData = ref<Response.OrderHistory.DefOrderRecords[]>()

onMounted(() => {
  getOrderHistory()
})

function getOrderHistory() {
  getHistoryOrder({
    page: page.value,
    size: size,
    start: startTime.value,
    end: endTime.value,
    sort: sort.value,
    sortby: sortby.value
  }).then((res) => {
    console.log('res::: ', res)
    tableData.value = res.data
    tatolPage.value = res.total
  })
}
function checkDetail(row: any) {
  console.log('row::: ', row)
}

function handlePageDown() {
  if (page.value > 1) {
    page.value--
    getOrderHistory()
  }
}
function handlePageUp() {
  if (page.value < tatolPage.value) {
    page.value++
    getOrderHistory()
  }
}
function changePage(val: number) {
  page.value = val
  getOrderHistory()
}
function dateChange(val: string[]) {
  startTime.value = val[0]
  endTime.value = val[1]
  getOrderHistory()
}
</script>

<template>
  <div class="OrderHistoryComBox">
    <div class="body">
      <div class="header">订单历史</div>
      <div class="datePicker">
        <span>变动查询</span>
        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="default" @change="dateChange" />
      </div>
      <div class="dataTable">
        <el-table :data="tableData" style="width: 100%" header-row-class-name="tableHeader">
          <el-table-column prop="time" label="时间">
            <template #default="{ row }">
              <span>{{ moment(row.time).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="order_id" label="订单ID" align="center"> </el-table-column>
          <el-table-column prop="product_name" label="产品类型" align="center"></el-table-column>
          <el-table-column prop="channel" label="渠道类型" align="center"> </el-table-column>
          <el-table-column prop="amount" label="扣款周期" align="center"></el-table-column>
          <el-table-column prop="balance" label="订单金额" align="center"> </el-table-column>
          <el-table-column prop="remark" label="订单状态" align="center"> </el-table-column>
          <el-table-column label="订单配置" align="center">
            <template #default="scope">
              <span style="color: #4273f8; cursor: pointer" @click="checkDetail(scope.row)">查看详情</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination background layout="prev, pager, next" :total="tatolPage" @prev-click="handlePageDown" @next-click="handlePageUp" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.OrderHistoryComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .body {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;

    .pages {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .dataTable {
      flex: 1;
    }

    .header {
      font-size: 16px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-bottom: 34px;
    }

    .datePicker {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 20px;
      gap: 20px;
      width: max-content;

      span {
        font-size: 14px;
        color: #4e5969;
      }
    }
  }
}
</style>
