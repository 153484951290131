<script setup lang="ts">
import logo from '@/assets/imgs/logo.png'
import logo_light from '@/assets/imgs/logo_light.png'
import logo_color from '@/assets/imgs/logo_color.png'
import { computed } from 'vue'
const { color } = defineProps<{ color: 'dark' | 'light' }>()

// @ts-ignore
const fitLogo = computed(() => {
  return color === 'dark' ? logo : logo_light
})
</script>

<template>
  <div class="GlobalLogoBox">
    <router-link to="/">
      <img :src="logo_color" alt="" class="logo" />
    </router-link>
  </div>
</template>

<style lang="less" scoped>
.GlobalLogoBox {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;

  a {
    display: inline-block;
    height: 100%;

    .logo {
      height: 100%;
    }
  }
}
</style>
