import autolog from 'autolog.js'
import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError, AxiosRequestHeaders } from 'axios'
import { getUrlWithouParams } from '..'
import Cookies from 'js-cookie'
import router from '@/router'
import { mainStore } from '@/store'

let store: ReturnType<typeof mainStore>

// Create an Axios instance with base configuration
const BaseUrl = process.env.NODE_ENV == 'development' ? (import.meta.env.VITE_IS_MOCK == 'true' ? import.meta.env.VITE_BASEURL_MOCK : import.meta.env.VITE_BASEURL_TEST) : import.meta.env.VITE_BASEURL
const axiosInstance = axios.create({
  baseURL: BaseUrl,
  timeout: 30000, // Request timeout in milliseconds
  maxRedirects: 0,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Request interceptor
axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Add any custom logic before the request is sent
    // For example, adding an authorization access_token
    const access_token = Cookies.get('access_token')
    if (access_token && access_token !== '') {
      config.headers['Authorization'] = `Bearer ${access_token}`
    }
    return config
  },
  (error: AxiosError) => {
    // Handle request error
    return Promise.reject(error)
  }
)

// Response interceptor
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.code && response.data.code !== 200 && (response.data.msg || response.data.error)) {
      autolog.log(response.data.msg || response.data.error, 'error')
    }
    // Any status code that lies within the range of 2xx causes this function to trigger
    if (
      !location.href.includes('localhost') &&
      getUrlWithouParams(response.request.responseURL) !== BaseUrl + response.config.url &&
      !response.config.url?.includes('captcha') &&
      !response.config.url?.includes('storage')
    ) {
      // Redirect
      window.location.replace(response.request.responseURL)
    }
    return response
  },
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx causes this function to trigger
    // Handle response error
    if (!store) store = mainStore()
    if (error.response) {
      if (error.response.status === 404) {
        autolog.log('请求的资源不存在', 'error')
        return Promise.reject(error)
      }
      if (error.response.status === 401) {
        // Unauthorized, redirect to login page
        Cookies.remove('access_token')
        autolog.log('登录过期，请重新登录', 'error')
        router.push('/login')
      }
      // Server responded with a status other than 200 range
      if (error.status === 429) {
        // autolog.log('请求过于频繁，请稍后重试', 'error')
        return Promise.resolve({ data: error.response.config.url && store.memoryCache[error.response.config.url], code: 200 })
      }
      if (error.response.data) {
        console.error('Response error:', error.response.data)
        let data = error.response.data as { [key: string]: any }
        if (data.msg || data.error) autolog.log(data.msg || data.error, 'error')
      }
    } else if (error.request) {
      // Request was made but no response was received
      console.error('Request error:', error.request)
      autolog.log('请求失败，请稍后重试', 'error')
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message)
      autolog.log('请求失败，请稍后重试', 'error')
    }
    return Promise.reject(error)
  }
)
const throttleQueue: { url: string; timer: NodeJS.Timeout }[] = []
// Example request methods
const get = async <T = any>(url: string, params?: { [key: string]: any }, customOptions?: { isThrottle?: boolean; localcache?: boolean }): Promise<API.Response<T>> => {
  if (!store) store = mainStore()
  if (customOptions?.isThrottle) {
    const index = throttleQueue.findIndex((item) => item.url === url)
    if (index !== -1) {
      clearTimeout(throttleQueue[index].timer)
      throttleQueue[index].timer = setTimeout(() => {
        throttleQueue.splice(index, 1)
      }, 1000)
      // Return cache data
      if (store.memoryCache[url]) {
        return store.memoryCache[url]
      }
    }
    throttleQueue.push({
      url,
      timer: setTimeout(() => {
        throttleQueue.shift()
      }, 1000)
    })
  }
  try {
    const response = await axiosInstance.get(url, {
      params: params
    })
    if (response.data && customOptions?.localcache) store.memoryCache[url] = response.data
    return response.data
  } catch (error) {
    if (store.memoryCache[url]) return store.memoryCache[url]
    throw error
  }
}

const post = async <T = any>(url: string, data: { [key: string]: any }, customOptions?: { headers: AxiosRequestHeaders | {} }): Promise<API.Response<T>> => {
  try {
    const response = await axiosInstance.post(url, data, {
      headers: customOptions?.headers
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export { get, post }
export default axiosInstance
