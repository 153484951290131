import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'

import HomeView from '@/pages/HomeView.vue'
import LoginView from '@/pages/LoginView.vue'
import ConsoleView from '@/Layout/BaseLayout/ConsoleView/ConsoleView.vue'
import OfficialView from '@/Layout/BaseLayout/OfficialView/OfficialView.vue'
import InstancesCom from '@/components/InstancesCom/InstancesCom.vue'
import OverviewCom from '@/components/OverviewCom/OverviewCom.vue'
import ImagesCom from '@/components/ImagesCom/ImagesCom.vue'
import StoragesCom from '@/components/StoragesCom/StoragesCom.vue'
import MarketCom from '@/components/MarketCom/MarketCom.vue'
import UserInfoCom from '@/components/UserInfoCom/UserInfoCom.vue'
import SecretsCom from '@/components/SecretsCom/SecretsCom.vue'
import BalanceCom from '@/components/BalanceCom/BalanceCom.vue'
import BillsHistoryCom from '@/components/BillsHistoryCom/BillsHistoryCom.vue'
import OrderHistoryCom from '@/components/OrderHistoryCom/OrderHistoryCom.vue'
import InvoiceCom from '@/components/InvoiceCom/InvoiceCom.vue'
import RechargeCom from '@/components/RechargeCom/RechargeCom.vue'
import PaymentCom from '@/components/PaymentCom/PaymentCom.vue'

import autolog from 'autolog.js'
import Cookies from 'js-cookie'

const routes: readonly RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
    component: HomeView,
    children: [
      {
        path: 'market',
        component: MarketCom,
        name: '算力市场',
        meta: {
          title: '算力市场',
          icon: 'icon-GPU'
        }
      },
      {
        path: 'home',
        component: OfficialView,
        name: '首页',
        meta: {
          title: '算力自由',
          hidden: true
        }
      },
      {
        path: 'console',
        component: ConsoleView,
        name: '控制台',
        meta: {
          title: '控制台-算力自由',
          position: 'right',
          icon: 'icon-kongzhitai'
        },
        redirect: '/console/overview',
        children: [
          {
            path: 'overview',
            component: OverviewCom,
            name: '总览',
            meta: {
              title: '总览-算力自由',
              icon: 'icon-zonglan1'
            }
          },
          {
            path: 'instances',
            component: InstancesCom,
            name: '我的实例',
            meta: {
              title: '实例-算力自由',
              icon: 'icon-shili1'
            }
          },
          {
            path: 'images',
            component: ImagesCom,
            name: '我的镜像',
            meta: {
              title: '镜像-算力自由',
              icon: 'icon-jingxiang'
            }
          },
          {
            path: 'storages',
            component: StoragesCom,
            name: '文件',
            meta: {
              title: '存储-算力自由',
              icon: 'icon-cunchu1'
            }
          },
          {
            path: 'bills',
            name: '费用中心',
            redirect: '/console/bills/balance',
            meta: {
              title: '费用中心-算力自由',
              hidden: true,
              icon: 'icon-feiyong1'
            },
            children: [
              { path: 'recharge', component: RechargeCom, name: '充值中心', meta: { title: '充值-算力自由' } },
              { path: 'balance', component: BalanceCom, name: '收支明细', meta: { title: '收支明细-算力自由' } },
              { path: 'bills', component: BillsHistoryCom, name: '账单历史', meta: { title: '账单历史-算力自由' } },
              { path: 'orders', component: OrderHistoryCom, name: '订单历史', meta: { title: '订单历史-算力自由' } },
              { path: 'invoice', component: InvoiceCom, name: '发票管理', meta: { title: '发票管理-算力自由' } },
              { path: 'payment', component: PaymentCom, name: '支付', meta: { title: '支付-算力自由', hidden: true } }
            ]
          },
          {
            path: 'user',
            name: '个人中心',
            redirect: '/console/user/infos',
            meta: {
              title: '个人中心-算力自由',
              hidden: true,
              icon: 'icon-geren'
            },
            children: [
              { path: 'infos', component: UserInfoCom, name: '个人信息', meta: { title: '个人信息-算力自由' } },
              { path: 'secrets', component: SecretsCom, name: '密钥管理', meta: { title: '密钥管理-算力自由' } }
            ]
          }
        ]
      },
      {
        path: 'model',
        component: OfficialView,
        name: '模型',
        meta: {
          title: '算力自由',
          icon: 'icon-moxing'
        }
      },
      {
        path: 'data',
        component: OfficialView,
        name: '数据',
        meta: {
          title: '算力自由',
          icon: 'icon-shuju'
        }
      }
    ]
  },
  {
    path: '/login',
    component: LoginView,
    meta: {
      title: '算力自由-登录'
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
const noTokenDomainRouterList = ['/login', '/register', '/forget', '/home'] // 不需要登录的路由
const unDoneRouterList = ['/model', '/data', '/console/images'] // 未完成的路由
router.beforeEach((to, _, next) => {
  if (unDoneRouterList.includes(to.path)) {
    autolog.log('暂未开放,敬请期待', 'info')
    return
  }
  document.title = to.meta.title as string
  if (noTokenDomainRouterList.includes(to.path)) {
    if (to.path === '/login') {
      if (Cookies.get('auth_reqid') && Cookies.get('access_token')) {
        Cookies.remove('auth_reqid', {
          domain: '.gpufree.cn'
        })
        Cookies.remove('access_token', {
          domain: '.gpufree.cn'
        })
      }
      // 如果没有auth_reqid则进入oidc，如果有auth_reqid但同时也有access_token，说明cookie有问题，auth_reqid是旧的,直接进入oidc
      if (!Cookies.get('auth_reqid') && process.env.NODE_ENV === 'production') {
        window.location.replace('https://dev.gpufree.cn/user/sign-in')
        return
      }
    } else {
      Cookies.remove('auth_reqid', {
        domain: '.gpufree.cn'
      })
    }
    next()
  } else {
    Cookies.get('access_token') ? next() : next('/login')
  }
})

export { routes }

export default router
