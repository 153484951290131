<script setup lang="ts">
import autolog from 'autolog.js'
import { ref, reactive, onMounted, onUnmounted } from 'vue'
import { ElForm, ElFormItem, ElInput, ElTabs, ElTabPane } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'
import oidc from '@/apis/auth'
import GlobalLogo from '@/Layout/modules/GlobalLogo/GlobalLogo.vue'
import Cookies from 'js-cookie'
import { mainStore } from '@/store'

const store = mainStore()

const ruleFormRef = ref<FormInstance>()
const activeName = ref('code')

const isShowForgetPassword = ref(false)
const forgetPasswordStep = ref(1)
const isGetedVcode = ref(0)
const isShowImageVCode = ref(false)
const imgVCodePass = ref(false)
const captcha_key = ref('')
const rand_num = ref('')
const serial_num = ref('')
const captchaData = reactive({
  thumb: '',
  thumbX: 0,
  thumbY: 0,
  thumbWidth: 67,
  thumbHeight: 67,
  image: ''
})
const form_code = reactive({
  phoneNumber: '',
  vCode: ''
})
const form_password = reactive({
  phoneNumber: '',
  password: ''
})
const form_resetPassWord = reactive({
  newPassWord: '',
  confirmPassWord: ''
})

onMounted(() => {
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      submitForm(ruleFormRef.value)
    }
  })
})
onUnmounted(() => {
  document.removeEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      submitForm(ruleFormRef.value)
    }
  })
})

const resetPassWordVVCode = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('submit!')
      forgetPasswordStep.value = 2
    } else {
      console.log('error submit!')
    }
  })
}
const otherLoginType = ref([
  {
    icon: 'icon-weixin',
    name: '微信登录',
    color: '#28c445',
    callback: () => {
      autolog.log('暂未开放', 'info')
    }
  }
])

const rules_code = reactive<FormRules<typeof form_code>>({
  phoneNumber: [
    {
      required: true,
      trigger: 'blur',
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      message: '请输入正确的手机号'
    }
  ],
  vCode: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
})
const rules_password = reactive<FormRules<typeof form_password>>({
  phoneNumber: [
    {
      required: true,
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      trigger: 'blur',
      message: '请输入正确的手机号'
    }
  ],
  password: [{ required: true, trigger: 'blur', message: '请输入密码' }]
})
const validatePass = (_: unknown, value: string, callback: Function) => {
  if (value === '') {
    callback(new Error('Please input the password'))
  } else {
    if (form_resetPassWord.confirmPassWord !== '') {
      if (!ruleFormRef.value) return
      ruleFormRef.value.validateField('checkPass')
    }
    callback()
  }
}
const validatePass2 = (_: unknown, value: string, callback: Function) => {
  if (value === '') {
    callback(new Error('Please input the password again'))
  } else if (value !== form_resetPassWord.newPassWord) {
    callback(new Error("Two inputs don't match!"))
  } else {
    callback()
  }
}
const rules_resetPassWord = reactive<FormRules<typeof form_resetPassWord>>({
  newPassWord: [{ required: true, validator: validatePass, trigger: 'blur', message: '请输入新密码' }],
  confirmPassWord: [{ required: true, validator: validatePass2, trigger: 'blur', message: '两次密码输入不一致' }]
})

const userAgreed = ref(false)
const submitForm = (formEl: FormInstance | undefined) => {
  if (serial_num.value == '' || !rand_num.value || !captcha_key.value) {
    return
  }
  if (isShowForgetPassword.value) {
    submitResetPassWord(formEl)
    return
  }
  if (!userAgreed.value) {
    autolog.log('请先同意用户协议', 'error')
    return
  }
  if (!formEl) return
  formEl.validate((valid, row) => {
    if (valid) {
      console.log('submit!')
      login()
    } else {
      console.log('row::: ', row)
      console.log('error submit!')
    }
  })
}

function submitResetPassWord(formEl: FormInstance | undefined) {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('submitResetPassWord submit!')
      login()
    } else {
      console.log('submitResetPassWord error submit!')
    }
  })
}

async function login() {
  if (serial_num.value == '' || !rand_num.value || !captcha_key.value) {
    autolog.log('请先获取验证码', 'error')
    return
  }
  if (activeName.value === 'code') {
    let res = await oidc.login({
      user: form_code.phoneNumber,
      secret: form_code.vCode,
      verifyType: 'code',
      id: Cookies.get('auth_reqid') as string,
      consent: userAgreed.value,
      rand_num: rand_num.value,
      serial_num: serial_num.value,
      captcha: captcha_key.value
    })
    if (res.code !== 200) {
      return
    }
    Cookies.remove('auth_reqid', {
      domain: '.gpufree.cn'
    })
    store.lastLoginTime = new Date().getTime()
  } else {
    let res = await oidc.login({
      user: form_password.phoneNumber,
      secret: form_password.password,
      verifyType: 'password',
      id: Cookies.get('auth_reqid') as string,
      consent: userAgreed.value
    })
    if (res.code !== 200) {
      return
    }
    Cookies.remove('auth_reqid', {
      domain: '.gpufree.cn'
    })
    store.lastLoginTime = new Date().getTime()
  }
}
const set: Set<Function> = new Set()
async function getVCode() {
  if (isGetedVcode.value > 0) return
  // @ts-ignore
  if (!form_code.phoneNumber || !rules_code.phoneNumber[0].pattern.test(form_code.phoneNumber)) {
    autolog.log('请输入正确的手机号', 'error')
    return
  }
  let res = await getImageVCode()
  if (!res) {
    autolog.log('未能验证你的身份', 'error')
    return
  }
  isGetedVcode.value = 60
  const timer = setInterval(() => {
    isGetedVcode.value--
    if (isGetedVcode.value <= 0) {
      clearInterval(timer)
    }
  }, 1000)
}

async function getSmsVCode(captcha_key: string) {
  let res = await oidc.getSmsVCode({
    mobile: form_code.phoneNumber,
    type: 'login',
    captcha: captcha_key,
    reqId: Cookies.get('auth_reqid') as string
  })
  if (res.code === 200) {
    autolog.log('验证码发送成功', 'success')
    rand_num.value = res.data.rand_num.toString()
    serial_num.value = res.data.serial_number.toString()
    store.lastRand_num = res.data.rand_num.toString()
    store.lastSerial_num = res.data.serial_number.toString()
  } else if (res.code === 40001) {
    rand_num.value = store.lastRand_num.toString()
    serial_num.value = store.lastSerial_num.toString()
    serial_num.value = store.lastSerial_num.toString()
  }
}
async function getImageVCode() {
  imgVCodePass.value = false
  isShowImageVCode.value = true
  captchaData.image = ''
  captchaData.thumb = ''
  let res = await oidc.getImageVCode({
    type: 'slide',
    sub_type: 'basic'
  })
  captcha_key.value = res.captcha_key
  captchaData.image = res.image_base64
  captchaData.thumbX = res.tile_x
  captchaData.thumbY = res.tile_y
  captchaData.thumbWidth = res.tile_width
  captchaData.thumbHeight = res.tile_height
  captchaData.thumb = res.tile_base64
  return new Promise((resolve) => {
    set.add(resolve)
  })
}
async function vCodeImgConfirm(CaptchaPoint: any, clear: Function) {
  let res = await oidc.checkImageVCode({
    key: captcha_key.value,
    point: `${CaptchaPoint.x},${CaptchaPoint.y}`
  })
  if (res.code === 200) {
    getSmsVCode(captcha_key.value)
    isShowImageVCode.value = false
    set.forEach((resolve) => {
      resolve(true)
    })
    set.clear()
  } else {
    autolog.log('验证失败', 'error')
    captchaData.thumbX = 0
    getImageVCode()
  }
  clear()
}
function cancelImageVCode() {
  isShowImageVCode.value = false
  captchaData.image = ''
  captchaData.thumbX = 0
  captchaData.thumbY = 0
  captchaData.thumbWidth = 67
  captchaData.thumbHeight = 67
  captchaData.thumb = ''
  set.forEach((resolve) => {
    resolve(false)
  })
  set.clear()
}
</script>

<template>
  <div class="LoginViewBox">
    <div class="body">
      <div class="welcomeImg">
        <img src="@/assets/imgs/loginWelcome.jpg" alt="" />
        <span class="slogan" v-if="false">算力自由</span>
      </div>
      <div class="loginFormBody" v-if="!isShowForgetPassword">
        <div class="logo">
          <GlobalLogo color="light" />
        </div>
        <div class="forms">
          <el-tabs v-model="activeName" class="demo-tabs">
            <el-tab-pane label="短信登录" name="code">
              <div class="codeLogin" v-if="activeName == 'code'">
                <el-form ref="ruleFormRef" style="max-width: 600px" :model="form_code" :rules="rules_code" class="demo-ruleForm">
                  <el-form-item prop="phoneNumber">
                    <el-input v-model="form_code.phoneNumber" autocomplete="off" placeholder="请输入手机号" />
                  </el-form-item>
                  <el-form-item prop="vCode">
                    <div class="getVCode">
                      <el-input v-model="form_code.vCode" autocomplete="off" :placeholder="`请输入验证码${serial_num ? '（序号为：' + serial_num + '）' : ''}`" />
                      <!-- @vue-skip -->
                      <span class="get" @click="getVCode" :class="{ disable: isGetedVcode > 0 || !rules_password.phoneNumber[0].pattern.test(form_code.phoneNumber) }">
                        {{ isGetedVcode > 0 ? isGetedVcode + 's' : '获取验证码' }}
                      </span>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="userAgreement">
                      <el-checkbox v-model="userAgreed" />
                      <div class="tip">
                        <span>我已阅读并同意 <a href="#">用户协议</a>、<a href="#">隐私政策</a>、<a href="#">产品服务协议</a> </span>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <span type="primary" tabindex="0" @click="submitForm(ruleFormRef)" class="loginBtn" :class="{ disable: serial_num == '' || !rand_num || !captcha_key }"> 登录/注册 </span>
                  </el-form-item>
                  <el-form-item>
                    <div class="userAgreement registryTip">
                      <div class="tip">
                        <span>未注册手机号会直接注册并登录</span>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <div class="otherLoginType">
                      <span>其他登录方式：</span>
                      <div class="icons" v-for="item in otherLoginType">
                        <span class="iconfont" :class="item.icon" @click="item.callback" :style="{ color: item.color }"></span>
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
            <el-tab-pane label="密码登录" name="password" lazy>
              <div class="passwordLogin" v-if="activeName == 'password'">
                <el-form ref="ruleFormRef" style="max-width: 600px" :model="form_password" :rules="rules_password" class="demo-ruleForm">
                  <el-form-item prop="phoneNumber">
                    <el-input v-model="form_password.phoneNumber" autocomplete="off" id="phone" placeholder="请输入手机号" />
                  </el-form-item>
                  <el-form-item prop="password">
                    <el-input v-model="form_password.password" autocomplete="off" type="password" placeholder="请输入密码" show-password />
                  </el-form-item>
                  <el-form-item>
                    <div class="userAgreement">
                      <el-checkbox v-model="userAgreed" />
                      <div class="tip">
                        <span>我已阅读并同意 <a href="#">用户协议</a>、<a href="#">隐私政策</a>、<a href="#">产品服务协议</a> </span>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <span type="primary" @click="submitForm(ruleFormRef)" class="loginBtn"> 登录 </span>
                    <a href="#" class="forgetPassword" @click="isShowForgetPassword = true">忘记密码？</a>
                  </el-form-item>

                  <el-form-item>
                    <div class="otherLoginType">
                      <span>其他登录方式：</span>
                      <div class="icons" v-for="item in otherLoginType">
                        <span class="iconfont" :class="item.icon" @click="item.callback" :style="{ color: item.color }"></span>
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="forgetPassWord" v-if="isShowForgetPassword">
        <div class="logo">
          <GlobalLogo color="light" />
        </div>
        <div class="forms">
          <div class="title">
            <span class="iconfont icon-fanhui-denglu" @click="isShowForgetPassword = false"></span>
            重置密码
          </div>
          <div class="codeLogin" v-if="isShowForgetPassword && forgetPasswordStep == 1">
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="form_code" :rules="rules_code" class="demo-ruleForm">
              <el-form-item prop="phoneNumber">
                <el-input v-model="form_code.phoneNumber" autocomplete="off" placeholder="请输入手机号" />
              </el-form-item>
              <el-form-item prop="vCode">
                <div class="getVCode">
                  <el-input v-model="form_code.vCode" autocomplete="off" placeholder="请输入验证码" />
                  <span class="get" @click="getVCode" :class="{ disable: isGetedVcode > 0 }">
                    {{ isGetedVcode > 0 ? isGetedVcode + 's' : '获取验证码' }}
                  </span>
                </div>
              </el-form-item>
              <el-form-item>
                <span type="primary" @click="resetPassWordVVCode(ruleFormRef)" class="loginBtn"> 下一步 </span>
              </el-form-item>
            </el-form>
          </div>
          <div class="passwordLogin" v-if="isShowForgetPassword && forgetPasswordStep == 2">
            <el-form ref="ruleFormRef" style="max-width: 600px" :model="form_resetPassWord" :rules="rules_resetPassWord" class="demo-ruleForm">
              <el-form-item prop="newPassWord">
                <el-input v-model="form_resetPassWord.newPassWord" autocomplete="off" placeholder="请输入新密码" type="password" show-password />
              </el-form-item>
              <el-form-item prop="confirmPassWord">
                <el-input v-model="form_resetPassWord.confirmPassWord" autocomplete="off" placeholder="请确认密码" type="password" show-password />
              </el-form-item>
              <el-form-item>
                <span type="primary" @click="submitForm(ruleFormRef)" class="loginBtn"> 确认 </span>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mask flex items-center justify-center" v-if="isShowImageVCode">
    <gocaptcha-slide :data="captchaData" :events="{ confirm: vCodeImgConfirm, refresh: getImageVCode, close: cancelImageVCode }" />
  </div>
</template>

<style lang="less" scoped>
.LoginViewBox {
  width: 100%;
  height: 100%;

  .body {
    width: 100%;
    height: 100%;
    display: flex;

    .welcomeImg {
      background: #00000015;
      width: 480px;
      height: 100%;
      position: relative;
      .slogan {
        position: absolute;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 34px;
        color: #ffffffce;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .loginFormBody,
    .forgetPassWord {
      position: relative;

      .logo {
        position: absolute;
        top: 34px;
        left: 38px;
        width: 100px;
        height: 60px;
      }

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .forms {
        :deep(input[type='text']),
        #phone {
          width: 380px;
          height: 42px;
        }
        :deep(input[type='password']) {
          height: 42px;
        }

        .title {
          display: inline-flex;
          width: 100%;
          font-size: 24px;
          padding-bottom: 55px;
          text-align: center;
          justify-content: center;
          align-items: center;
          position: relative;

          .iconfont {
            position: absolute;
            left: 0;
            font-size: 32px;
            cursor: pointer;
          }
        }

        .otherLoginType {
          display: flex;
          margin-top: 24px;

          .iconfont {
            font-size: 32px;
            cursor: pointer;
          }
        }

        :deep(.getVCode input[type='text']) {
          width: 240px;
        }

        .userAgreement {
          margin-top: 18px;
          display: flex;
          align-items: flex-start;
          gap: 10px;
          &.registryTip {
            margin-top: 0;
          }
          .tip {
            display: flex;
            flex-direction: column;
            line-height: 20px;
            gap: 0;
          }

          a {
            color: var(--theme-main-color);
          }
        }

        .loginBtn {
          width: 100%;
          height: 42px;
          background: var(--theme-main-color);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;
          transition: 0.2s;

          &:focus {
            outline: 1px solid #000;
          }

          &:hover {
            background: #f19100cc;
          }
          &.disable {
            background: #8b8b8b66;
            cursor: not-allowed;
          }
        }

        .codeLogin {
          .getVCode {
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            gap: 20px;
            width: 100%;

            .get {
              display: inline-flex;
              color: #fff;
              cursor: pointer;
              background: #86c13d;
              padding: 12px 24px;
              font-size: 14px;
              line-height: 20px;
              border-radius: 4px;
              transition: 0.2s;
              align-items: center;
              justify-content: center;

              &.disable {
                background: #8b8b8b66;
                cursor: not-allowed;
                width: 100%;
              }

              &:not(.disable):hover {
                background: #86c13dcc;
              }
            }
          }
        }

        .passwordLogin {
          .forgetPassword {
            color: var(--theme-main-color);
            width: 100%;
            padding-top: 10px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.loginFormBody {
  .el-tabs__nav-scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 44px;
  }

  .el-tabs__item {
    font-size: 18px !important;
  }

  .el-tabs__nav-wrap::after {
    display: none;
  }

  .el-tabs__active-bar {
    height: 4px;
  }

  .el-checkbox {
    margin-right: 0px;
    height: 20px;
  }

  .el-checkbox__inner {
    border: 1px solid #979797;
  }
}
</style>
