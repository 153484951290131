<script setup lang="ts">
import { ref } from 'vue'
import { ElDialog, ElForm, ElInput, ElFormItem } from 'element-plus'
import eventBus from '@/utils/eventBus'
import Cookies from 'js-cookie'

const emit = defineEmits(['verifySuccess', 'verifyFail', 'verifyCancel'])

eventBus.on('showUnifiedVerificationModule', () => {
  isShowChangePhone.value = true
})

const isShowChangePhone = ref(false)
const changePhoneForm = ref({
  vCode: ''
})
const changePhoneForm_rules = ref({
  vCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
})

function closed() {
  isShowChangePhone.value = false
  emit('verifyCancel')
}

function goVerify() {
  if (changePhoneForm.value.vCode !== '123456') {
    emit('verifyFail')
    return
  }
  isShowChangePhone.value = false
  emit('verifySuccess')
}
</script>

<template>
  <div class="UnifiedVerificationModuleBox">
    <el-dialog v-model="isShowChangePhone" title="验证手机" width="500" center modal-class="changePhoneNumber" @close="closed">
      <el-form ref="changePhoneFormRef" :model="changePhoneForm" :rules="changePhoneForm_rules" label-width="100px">
        <div class="changePhone">
          <el-form-item label="您的手机号">
            <div class="vCode">
              <span>{{ Cookies.get('username') }}</span>
            </div>
          </el-form-item>
          <el-form-item label="验证码" prop="vCode">
            <div class="vCode">
              <el-input v-model="changePhoneForm.vCode" />
              <span class="send">发送验证码</span>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <span type="primary" class="Confirm" @click="goVerify"> 下一步 </span>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.UnifiedVerificationModuleBox {
  width: 100%;
  height: 100%;
}
</style>
