<script setup lang="ts">
import { ref, onBeforeMount, onMounted, readonly } from 'vue'
import { getProductList, getProductNameList, getDatacenterList } from '@/apis/prodData'
import { getAllImages, createDevMachine } from '@/apis/devMachine'
import { ElScrollbar, ElSelect, ElInputNumber, ElOption, ElPopover, ElCascader, ElDialog, ElButton, CascaderValue } from 'element-plus'
import { bytes2GB, GB2Bytes } from '@/utils'
import autolog from 'autolog.js'
import router from '@/router'

const cols = 3
const chargeTypes = {
  0: '按量计费'
}
const GPUCounts = [1, 2, 3, 4, 5, 6, 7, 8, 10]
const prods = ref<products>([])
const imagesTree = ref<imagesCascader>([])
const prodsByProdsName = ref<products>([])
const activeProdId = ref<string | number>('')
const scrollBar = ref<InstanceType<typeof ElScrollbar>>()
const isTipChooseImage = ref(false)
const isTipChooseGpu = ref(false)
const currImageNotes = ref('')

const configFormDefaultOrigin = readonly({
  product_id: '',
  data_center_id: '',
  image_id: '',
  charge_type: 0,
  req_amount: 1,
  start_model: 'gpu',
  expand_data_disk: 0
})

const configForm = ref<{
  product_id: string
  data_center_id: string
  image_id: string
  charge_type: number
  req_amount: number
  start_model: 'gpu' | 'nogpu'
  expand_data_disk: number
}>({
  product_id: '',
  data_center_id: '',
  image_id: '',
  charge_type: 0,
  req_amount: 1,
  start_model: 'gpu',
  expand_data_disk: 0
})

const sys_disk_size = ref<number>(0)
const data_disk_size = ref<number>(0)
const currSumPrice = ref<number>(0)
const billItems = ref<{ name: string; price: number; unit: string }[]>([])
const regionList = ref<regionSelect>([])
const isShowMoreConfig = ref<boolean>(false)
const query = router.currentRoute.value.query
const isShowConfirmClose = ref(false)

onBeforeMount(async () => {
  resetComfigForm()
  getImages()
  getRegions()
})
onMounted(async () => {
  prods.value = setAvailableProds(await getProductNames())
  viaRecommend()
})
function resetComfigForm() {
  configForm.value = {
    product_id: '',
    data_center_id: '',
    image_id: '',
    charge_type: 0,
    req_amount: 1,
    start_model: 'gpu',
    expand_data_disk: 0
  }
  sys_disk_size.value = 0
  data_disk_size.value = 0
  currSumPrice.value = 0
  billItems.value = []
  isTipChooseImage.value = false
  isTipChooseGpu.value = false
}

function setAvailableProds(prods: products) {
  for (let item of prods) {
    if (item.extra_info.left == 0) {
      item.disabled = true
    }
  }
  // 把售罄的放到最后
  prods.sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1
    } else if (!a.disabled && b.disabled) {
      return -1
    } else {
      return 0
    }
  })
  return prods
}

function getImages() {
  getAllImages().then((res) => {
    imagesTree.value = setElCascader(res.data)
  })
}
function setElCascader(tree: images & imagesCascader) {
  if (!tree) {
    autolog.log('获取镜像失败', 'error')
    return []
  }
  for (let item of tree) {
    item.value = item.image_name
    item.label = item.image_name
    if (item.image_versions) {
      item.children = []
      for (let version of item.image_versions) {
        item.children.push({
          value: version.image_id,
          label: version.version,
          notes: version.notes
        })
      }
    }
  }
  return tree
}

function getRegions() {
  getDatacenterList().then((res) => {
    for (let item of res.data) {
      regionList.value.push({
        label: item.data_center_name,
        value: item.data_center_id
      })
    }
  })
}

async function getProductNames() {
  const res = await getProductNameList()
  return res.data
}

async function getProds(params?: { data_center_id?: string; gpu_request_amount?: string; product_names?: string }) {
  const res = await getProductList(params)
  return res.data
}

function viaRecommend() {
  if (query.product_name) {
    let item = prods.value.find((item) => item.product_name == query.product_name)
    if (!item) {
      autolog.log('找不到该产品', 'error')
      return
    }
    if (item.extra_info.left <= 0) {
      autolog.log('您所选的算力已售罄，请查看其他算力资源。', 'error')
      router.replace('/market')
      return
    }
    prods.value.map((item, index: number) => {
      if (item.product_name == query.product_name) {
        itemClick(item, index / cols, query.product_id as string)
      }
    })
  }
}

async function itemClick(item: ArrayItem<products>, rowIndex: number, checkedProdId?: string, data_center_id?: string) {
  if (item.product_id == activeProdId.value) {
    closeOrChangeCheck()
    return
  }
  if (item.extra_info.left <= 0 && !checkedProdId) {
    autolog.log('您所选的算力已售罄，请查看其他算力资源。', 'error')
    router.replace('/market')
    return
  }
  await closeOrChangeCheck()
  resetComfigForm()
  let res = await getProds({
    product_names: item.product_name,
    gpu_request_amount: configForm.value.req_amount.toString(),
    data_center_id: data_center_id
  })
  isShowMoreConfig.value = false
  prodsByProdsName.value = res
  activeProdId.value = item.product_id
  let checkedProd = prodsByProdsName.value.find((item) => item.product_id == checkedProdId && item.extra_info.left > 0)
  if (!checkedProd) {
    let isExist = prodsByProdsName.value.find((item) => item.product_id == checkedProdId)
    if (isExist && isExist.extra_info.left == 0) {
      isShowMoreConfig.value = true
      autolog.log('您挑选的配置已售罄', 'error')
      configForm.value.product_id = isExist.product_id
    }
  }
  if (!configForm.value.product_id && checkedProd) {
    configForm.value.product_id = checkedProd.product_id
  }
  if (!configForm.value.product_id) {
    configForm.value.product_id = res[0].product_id
  }
  sys_disk_size.value = res[0].sys_disk_size
  data_disk_size.value = res[0].data_disk_size
  setRegionIsAvailableByprodsByProdsNameLeft(
    prodsByProdsName.value.map((originItem, index: number) => {
      if (originItem.product_id == configForm.value.product_id) {
        return index
      }
    })[0]
  )
  scrollBar.value?.scrollTo(0, rowIndex * 136)
  billItems.value = [
    {
      name: res[0].product_name + ` × ${configForm.value.req_amount}`,
      price: (res[0].current_price / 100) * configForm.value.req_amount,
      unit: '时'
    },
    {
      name: `数据盘 ${bytes2GB(data_disk_size.value + configForm.value.expand_data_disk)} GB`,
      price: bytes2GB(configForm.value.expand_data_disk) * 0.07,
      unit: '天'
    }
  ]
  currSumPrice.value = billItems.value.reduce((acc, cur) => acc + cur.price, 0)
}

function setRegionIsAvailableByprodsByProdsNameLeft(prodsByProdsNameIndex: number = 0) {
  for (let [key, value] of Object.entries(prodsByProdsName.value[prodsByProdsNameIndex].extra_info.dac_left)) {
    if (value == 0) {
      regionList.value.forEach((region) => {
        if (region.value == key) {
          region.disabled = true
        }
      })
    } else {
      regionList.value.forEach((region) => {
        if (region.value == key) {
          region.disabled = false
        }
      })
    }
  }
  for (let region of regionList.value) {
    if (!region.disabled) {
      configForm.value.data_center_id = region.value
      sys_disk_size.value = prodsByProdsName.value[prodsByProdsNameIndex].sys_disk_size
      data_disk_size.value = prodsByProdsName.value[prodsByProdsNameIndex].data_disk_size
      break
    }
  }
}
function tgIsShowMoreConfig() {
  isShowMoreConfig.value = !isShowMoreConfig.value
}
function handleCheckSpecific(item: ArrayItem<products>) {
  if (item.extra_info.left == 0) {
    autolog.log('该配置已售罄', 'error')
    return
  }
  setRegionIsAvailableByprodsByProdsNameLeft(prodsByProdsName.value.indexOf(item))
  configForm.value.product_id = item.extra_info.left > 0 ? item.product_id : ''
  sys_disk_size.value = item.sys_disk_size
  data_disk_size.value = item.data_disk_size
  billItems.value[0].price = (item.current_price / 100) * configForm.value.req_amount
  currSumPrice.value = billItems.value.reduce((acc, cur) => acc + cur.price, 0)
}
function handleChange(value: CascaderValue) {
  value = value as string
  configForm.value.image_id = value[value.length - 1]
  for (let item of imagesTree.value) {
    if (item.children) {
      for (let child of item.children) {
        if (child.value == value[value.length - 1]) {
          currImageNotes.value = child.notes!
        }
      }
    }
  }
  isTipChooseImage.value = false
}
async function submit() {
  if (!configForm.value.product_id) {
    autolog.log('该配置已售罄', 'error')
    return
  }
  if (configForm.value.product_id && prodsByProdsName.value.find((item) => item.product_id == configForm.value.product_id)?.extra_info.left == 0) {
    autolog.log('该配置已售罄', 'error')
    return
  }
  if (!configForm.value.product_id) {
    isTipChooseGpu.value = true
    return
  }
  if (!configForm.value.image_id) {
    isTipChooseImage.value = true
    return
  }
  if (!configForm.value.image_id || !configForm.value.data_center_id || !configForm.value.product_id) {
    autolog.log('请完善配置信息', 'error')
    return
  } else {
    console.log('configForm.value::: ', configForm.value)
  }
  let finnalConfig = {
    ...configForm.value,
    expand_data_disk: GB2Bytes(configForm.value.expand_data_disk)
  }
  let res = await createDevMachine(finnalConfig)
  if (res.code == 200) {
    autolog.log('创建成功', 'success')
    router.push('/console/instances')
  }
}
async function changeReqAmount(amount: number) {
  configForm.value.req_amount = amount
  billItems.value[0].price = (prodsByProdsName.value[0].current_price / 100) * amount
  currSumPrice.value = billItems.value.reduce((acc, cur) => acc + cur.price, 0)
  let res = await getProds({
    product_names: prodsByProdsName.value[0].product_name,
    gpu_request_amount: amount.toString(),
    data_center_id: configForm.value.data_center_id
  })
  prodsByProdsName.value = res
  configForm.value.product_id = res[0].extra_info.left > 0 ? res[0].product_id : ''
  if (!configForm.value.product_id) {
    isTipChooseGpu.value = true
  } else {
    isTipChooseGpu.value = false
  }
}
function clearCheck() {
  activeProdId.value = ''
  resetComfigForm()
  isShowConfirmClose.value = false
  set.forEach((fn) => {
    fn()
  })
}
const set = new Set<Function>()
function closeOrChangeCheck() {
  if (
    configForm.value.req_amount !== configFormDefaultOrigin.req_amount ||
    configForm.value.charge_type !== configFormDefaultOrigin.charge_type ||
    configForm.value.image_id !== configFormDefaultOrigin.image_id ||
    configForm.value.expand_data_disk !== configFormDefaultOrigin.expand_data_disk
  ) {
    isShowConfirmClose.value = true
    return new Promise((resolve) => {
      set.add(resolve)
    })
  } else {
    clearCheck()
    return true
  }
}
async function regionChange(data_center_id: string) {
  let product_name = prodsByProdsName.value[0].product_name
  let res = await getProds({
    product_names: product_name,
    gpu_request_amount: configForm.value.req_amount.toString(),
    data_center_id: data_center_id
  })
  prodsByProdsName.value = res
  configForm.value.product_id = res[0].extra_info.left > 0 ? res[0].product_id : ''
  if (!configForm.value.product_id) {
    isTipChooseGpu.value = true
  } else {
    isTipChooseGpu.value = false
  }
}
</script>

<template>
  <div class="MarketComBox">
    <div class="body">
      <el-scrollbar height="100%" ref="scrollBar" noresize style="overflow-x: hidden">
        <div class="prodGrid">
          <div
            v-for="(item, index) in prods"
            :key="item.product_id"
            class="prodItem"
            :class="{ active: activeProdId === item.product_id, [`col${index % cols}`]: true, [`row${Math.floor(index / cols)}`]: true, disabled: item.disabled }"
            :id="`product_id${item.product_id}`">
            <div class="pordInfo" @click.stop="itemClick(item, Math.floor(index / cols))">
              <div class="header">
                <div class="prodName">
                  <span
                    class="iconfont"
                    :class="{ 'icon-yingweida': item.gpu_name == 'NVIDIA', 'icon-GPU1': item.gpu_name == 'CPU' }"
                    :style="`color:${item.gpu_name == 'NVIDIA' ? '#76b900' : ''}`"></span>
                  <span :style="{ fontSize: (item.product_name.length > 21 ? 14 : 20) + 'px' }"> {{ item.product_name }}</span>
                </div>
                <div class="prodPrice">
                  <span>￥</span>
                  <span> {{ (item.current_price / 100).toFixed(2) }}</span>
                  <span> 起 /时</span>
                </div>
              </div>
              <div class="infos">
                <div class="cpu">
                  <span>CPU</span>
                  <span>vCPU * {{ item.cpu_per_gpu }} 核起</span>
                </div>
                <div class="memory">
                  <span>内存</span>
                  <span>{{ bytes2GB(item.mem_per_gpu) }} GB 起</span>
                </div>
                <div class="disk">
                  <span>系统盘</span>
                  <span>{{ bytes2GB(item.sys_disk_size) }} GB 起</span>
                </div>
                <div class="dataDisk">
                  <span>数据盘</span>
                  <span>{{ bytes2GB(item.data_disk_size) }} GB 起</span>
                </div>
              </div>
            </div>
            <Transition name="growAni">
              <div class="activeBox" v-if="activeProdId === item.product_id" :style="{ right: `calc(${(index % cols) * 100}% + ${(index % cols) * 10}px)` }">
                <div class="detailInfos">
                  <div class="configForm">
                    <div class="configItem">
                      <span class="itemName">计费方式</span>
                      <div class="itemConfig">
                        <div class="chargeType radio">
                          <span v-for="(item, index) in chargeTypes" @click.stop="configForm.charge_type = index" :class="{ active: configForm.charge_type == index }">{{ item }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="configItem">
                      <span class="itemName">算力区域</span>
                      <div class="itemConfig">
                        <el-select v-model="configForm.data_center_id" filterable placeholder="地区选择" style="width: 180px !important" @change="regionChange">
                          <el-option v-for="item in regionList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled" />
                        </el-select>
                      </div>
                    </div>
                    <div class="configItem" v-if="item.mem_per_gpu > 0">
                      <span class="itemName">GPU数量</span>
                      <div class="itemConfig">
                        <div class="radio">
                          <span v-for="(item, index) in GPUCounts" @click.stop="changeReqAmount(index + 1)" :class="{ active: configForm.req_amount == index + 1 }">{{ item }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="configItem" v-if="prodsByProdsName">
                      <span class="itemName">配置</span>
                      <el-scrollbar wrap-class="scrollbar">
                        <div class="itemConfig">
                          <div ref="gpuCardRef" class="GPUCard" @click="handleCheckSpecific(prodsByProdsName[0])" :class="{ disabled: prodsByProdsName[0].extra_info.left == 0 }">
                            <div>
                              <div class="checkTag">
                                <span class="iconfont icon-duigou" v-if="configForm.product_id == prodsByProdsName[0].product_id"></span>
                              </div>
                              <span class="name">{{ prodsByProdsName[0].product_name }}</span>
                              <div class="baseInfos">
                                <div class="infoItem">
                                  <span>CPU </span>
                                  <span>vCPU * {{ item.cpu_per_gpu }} 核</span>
                                </div>
                                <div class="infoItem">
                                  <span>内存 </span>
                                  <span>{{ bytes2GB(prodsByProdsName[0].mem_per_gpu) }} GB</span>
                                </div>
                                <div class="infoItem">
                                  <span>可扩充数据盘 </span>
                                  <span>敬请期待</span>
                                </div>
                              </div>
                            </div>
                            <div v-if="isTipChooseGpu" class="isTipChooseGpu">请选择一个可用的配置</div>
                          </div>
                          <div class="moreConfig" @click.stop="tgIsShowMoreConfig" v-if="prodsByProdsName.length > 1 && !isShowMoreConfig">
                            <span>更多配置</span>
                            <span class="iconfont icon-gengduo"></span>
                          </div>
                          <div class="noMore" v-if="prodsByProdsName.length < 1 && !isShowMoreConfig">暂无更多配置</div>
                          <div class="GPUCard" v-for="item in prodsByProdsName.slice(1)" v-if="isShowMoreConfig" @click="handleCheckSpecific(item)" :class="{ disabled: item.extra_info.left == 0 }">
                            <div>
                              <div class="checkTag"><span class="iconfont icon-duigou" v-if="configForm.product_id == item.product_id"></span></div>
                              <span class="name">{{ item.product_name }}</span>
                              <div class="baseInfos">
                                <div class="infoItem">
                                  <span>CPU </span>
                                  <span>vCPU * {{ item.cpu_per_gpu }} 核</span>
                                </div>
                                <div class="infoItem">
                                  <span>内存 </span>
                                  <span>{{ bytes2GB(item.mem_per_gpu) }} GB</span>
                                </div>
                                <div class="infoItem">
                                  <span>可扩充数据盘 </span>
                                  <span>敬请期待</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </el-scrollbar>
                    </div>
                    <div class="configItem">
                      <span class="itemName">系统盘</span>
                      <div class="itemConfig">{{ bytes2GB(sys_disk_size) }} GB</div>
                    </div>
                    <div class="configItem">
                      <span class="itemName">数据盘</span>
                      <div class="itemConfig">
                        <span class="free">{{ bytes2GB(data_disk_size) }} + </span>
                        <el-popover placement="top-start" width="200" trigger="hover" content="敬请期待">
                          <template #reference>
                            <div style="display: flex; align-items: center; gap: 8px">
                              <el-input-number size="small" v-model="configForm.expand_data_disk" controls-position="right" :min="0" :max="100" disabled>
                                <template #suffix>
                                  <span>GB</span>
                                </template>
                              </el-input-number>
                              <div class="tip">
                                <span class="price">￥0.07</span>
                                <span> / GB </span>
                                <span> / 天 </span>
                              </div>
                            </div>
                          </template>
                        </el-popover>
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="configItem">
                      <span class="itemName">选择镜像</span>
                      <div class="itemConfig imgMust">
                        <el-cascader
                          ref="imgRef"
                          v-model="configForm.image_id"
                          :options="imagesTree"
                          :class="{ error: isTipChooseImage }"
                          @change="handleChange"
                          size="large"
                          placeholder="请选择一个镜像">
                          <template #default="{ data }">
                            <div class="cascaderItem">
                              <span>{{ data.label }}</span>
                              <span v-if="data.notes"> ({{ data.notes }}) </span>
                            </div>
                          </template>
                        </el-cascader>
                        <div class="currImageNotes">{{ currImageNotes }}</div>
                        <div v-if="isTipChooseImage" style="font-size: 12px; color: #ff4949">请选择一个镜像</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bill">
                  <div class="close" @click="closeOrChangeCheck">×</div>
                  <div class="header">
                    <span>合计</span>
                    <div class="price">
                      <span>￥ {{ currSumPrice.toFixed(2) }}</span>
                      <span class="unit"> /时</span>
                    </div>
                  </div>
                  <div class="line"></div>
                  <div class="body">
                    <div class="priceDetail">
                      <div class="header">
                        <span>实例费用：</span>
                      </div>
                      <div class="billItems" v-for="item in billItems">
                        <span>{{ item.name }}</span>
                        <div>
                          <span>￥{{ item.price }}</span>
                          <span>/{{ item.unit }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit">
                    <span @click="submit" :class="{ disable: isTipChooseGpu }">立即创建</span>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
  <el-dialog v-model="isShowConfirmClose" width="500" align-center style="padding: 40px">
    <span>您所选的配置不会被保存，确认关闭配置页面重新选择GPU吗</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="isShowConfirmClose = false">取消</el-button>
        <el-button type="primary" @click="clearCheck"> 确定 </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="less" scoped>
@height: 136px;
@activeHeight: 662px;

.MarketComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;

  .body {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    scroll-behavior: smooth;

    .prodGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      row-gap: 0px;
      transition: 0.8s;
      padding-bottom: @activeHeight;

      .line {
        height: 1px;
        background: #e5e7eb;
        width: 100%;
        margin: 20px 0;
      }

      .prodItem {
        transition: 0.8s;
        height: max-content;

        .pordInfo {
          padding: 20px;
          border-radius: 8px;
          background: #fff;
          cursor: pointer;
          height: @height;
          margin: 10px 0;
          box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
          border: 2px solid #f1910000;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            line-height: 25px;

            .prodName {
              display: flex;
              align-items: center;
              font-size: 20px;
              white-space: nowrap;
              font-weight: bold;

              .iconfont {
                transform: translateY(2px);
                font-weight: normal;
              }
            }

            .prodPrice {
              white-space: nowrap;

              span:nth-child(1) {
                font-size: 16px;
                color: var(--theme-main-color);
              }

              span:nth-child(2) {
                font-size: 24px;
                color: var(--theme-main-color);
              }
            }

            .iconfont {
              font-size: 32px;
            }
          }

          .infos {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 12px;
            column-gap: 40px;
            font-size: 14px;
            white-space: nowrap;
            .cpu {
              width: 100%;
            }

            span:first-child {
              color: #9c9c9c;
              padding-right: 10px;
              min-width: 60px;
              display: inline-block;
            }

            span:last-child {
              color: #151515;
            }
          }
        }

        .activeBox {
          height: @activeHeight;
          width: calc(300% + 20px);
          position: relative;
          // animation: grow 0.8s;
          border-radius: 8px;
          overflow: hidden;
          display: flex;

          .detailInfos {
            height: 100%;
            background: #fff;
            flex: 1;
            width: 0;
            padding: 30px 0;

            .configForm {
              display: flex;
              flex-direction: column;
              gap: 16px;
              padding: 0 30px;

              .configItem {
                display: flex;
                align-items: center;

                .itemName {
                  min-width: 100px;
                  text-align: left;
                  font-size: 14px;
                }

                .itemConfig {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 12px;
                  position: relative;
                  &.imgMust {
                    width: 100%;
                    :deep(.el-cascader) {
                      width: 100%;
                    }
                  }
                  .currImageNotes {
                    font-size: 14px;
                    color: #4e5969;
                    position: absolute;
                    top: 100%;
                    right: 6px;
                    padding-top: 10px;
                    color: #9c9c9c;
                  }

                  :deep(.error) {
                    border: 1px solid #ff4949;
                  }

                  .free {
                    font-size: 14px;
                  }

                  .tip {
                    font-size: 14px;
                    color: #4e5969;

                    .price {
                      color: var(--theme-main-color);
                    }
                  }

                  .moreConfig {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    white-space: nowrap;
                    cursor: pointer;

                    span {
                      font-size: 12px;
                      color: var(--theme-main-color);
                    }
                  }

                  .noMore {
                    color: #cccccc;
                  }

                  .GPUCard {
                    -webkit-clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 88%, 100% 13%, 95% 0);
                    clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 88%, 100% 13%, 95% 0);
                    background: var(--theme-main-color);
                    min-width: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 1px;
                    position: relative;
                    cursor: pointer;

                    .isTipChooseGpu {
                      font-size: 12px;
                      color: #ff0000;
                      position: absolute;
                      right: 22px;
                      bottom: 22px;
                    }

                    .checkTag {
                      position: absolute;
                      top: 20px;
                      right: 20px;
                      width: 16px;
                      height: 16px;
                      background: #fff;
                      border-radius: 100px;
                      border: 1px solid var(--theme-main-color);
                      transform: translateY(-50%);

                      .iconfont {
                        font-size: 6px;
                        background-color: var(--theme-main-color);
                        color: #fff;
                        border-radius: 100px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                    .name {
                      font-size: 18px;
                    }

                    > div:not(.isTipChooseGpu) {
                      display: flex;
                      flex-direction: column;
                      padding: 10px 16px;
                      gap: 8px;
                      -webkit-clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 88%, 100% 13%, 95% 0);
                      clip-path: polygon(0 0, 0 100%, 95% 100%, 100% 88%, 100% 13%, 95% 0);
                      height: 100%;
                      width: 100%;
                      background-image: url('@/assets/imgs/fan.png');
                      background-color: #fff;
                      background-size: 70% auto;
                      background-repeat: no-repeat;
                      background-position: 50% 50%;
                    }

                    .baseInfos {
                      display: flex;
                      flex-direction: column;
                      gap: 8px;

                      .infoItem {
                        display: flex;
                        justify-content: flex-start;
                        gap: 10px;
                        font-size: 12px;

                        span:first-child {
                          color: #9c9c9c;
                        }
                      }
                    }
                  }

                  .radio {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    background: #f2f3f5;
                    border-radius: 8px;
                    padding: 4px;
                    height: 36px;
                    font-size: 14px;

                    span {
                      padding: 5px 10px;
                      border: 1px solid #cccccc00;
                      border-radius: 4px;
                      cursor: pointer;

                      &.active {
                        border: 1px solid var(--theme-main-color);
                        background: #fff;
                        border-radius: 8px;
                      }

                      &:not(.active):hover {
                        background: #fdfeff;
                      }
                    }
                  }
                }
              }
            }
          }

          .bill {
            height: 100%;
            width: 343px;
            padding-top: 30px;
            padding-right: 30px;
            background: #fafafa;

            .close {
              position: absolute;
              top: 30px;
              right: 30px;
              cursor: pointer;
              font-size: 24px;
              color: #9c9c9c;
              cursor: pointer;

              &:hover {
                color: #ff4949;
              }
            }

            .submit {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              padding: 20px;
              padding-right: 40px;

              span {
                padding: 8px 14px;
                background: var(--theme-main-color);
                color: #fff;
                border-radius: 8px;
                cursor: pointer;

                &.disable {
                  background: #cccccc;
                  cursor: not-allowed;
                }
              }
            }

            > .header {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              padding: 0 20px;
              padding-right: 40px;
              height: 60px;

              > span {
                font-size: 16px;
                color: #151515;
                font-weight: bold;
              }

              .price {
                span {
                  font-size: 36px;
                  color: var(--theme-main-color);
                }

                .unit {
                  font-size: 14px;
                }
              }
            }

            > .body {
              padding: 0 20px;
              height: max-content;

              .priceDetail {
                .header {
                  font-size: 14px;
                  color: #4e5969;
                  padding: 10px 0;
                }

                .billItems {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  padding: 10px 0;
                  padding-right: 20px;
                  border-bottom: 1px solid #f1f5f9;
                  white-space: nowrap;

                  > span {
                    display: inline-block;
                    max-width: 60%;
                    white-space: wrap;
                  }

                  span {
                    font-size: 14px;
                    color: #4e5969;
                  }

                  div {
                    font-size: 14px;
                    color: var(--theme-main-color);
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  }
                }
              }
            }
          }
        }
        .growAni-enter-active,
        .growAni-leave-active {
          transition: 0.8s;
        }

        .growAni-enter-from,
        .growAni-leave-to {
          height: 0;
          opacity: 0;
        }
        &.active .pordInfo {
          border: 2px solid var(--theme-main-color);
          box-shadow: none;
          transition: 0.8s;
          position: relative;
          overflow: visible;
        }
      }

      .disabled {
        cursor: not-allowed !important;
        filter: grayscale(0.8);
        position: relative;
        color: #ccc;

        span {
          color: #ccc !important;
        }

        .pordInfo::after {
          content: '售罄';
          position: absolute;
          top: 50%;
          right: 30px;
          opacity: 0.8;
          padding: 4px 10px;
          border: 3px solid var(--theme-main-color);
          transform: rotate(-20deg) translateY(-50%);
          color: var(--theme-main-color);
          z-index: 1;
          font-weight: bolder;
        }
      }
    }
  }
}

.cascaderItem {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  gap: 2px;

  span {
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span:nth-child(2) {
    color: #9c9c9c;
    font-weight: normal;
    font-size: 12px;
  }
}

@keyframes grow {
  from {
    height: 0;
  }

  to {
    height: @activeHeight;
  }
}
</style>
<style lang="less">
.el-popper.el-cascader__dropdown {
  .el-cascader-menu {
    width: 240px;
  }
}

.MarketComBox + div .dialog-footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 30px;
}
</style>
