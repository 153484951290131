<script setup lang="ts">
import { ref } from 'vue'
import Recommend from './Recommend/Recommend.vue'
import banner1 from '@/assets/imgs/banner.jpg'

const bannerList = ref([
  {
    img: banner1,
    mainTitle: 'mainTitle',
    subTitle: 'subTitle'
  }
])
</script>

<template>
  <div class="OfficialComBox">
    <div class="swiper">
      <div class="swiper-item" v-for="item in bannerList">
        <img :src="item.img" alt="" />
      </div>
    </div>
    <Recommend />
  </div>
</template>

<style lang="less" scoped>
.OfficialComBox {
  width: 100%;
  height: 100%;

  .swiper {
    width: 100%;

    .swiper-item {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      background: #5f3bf6;

      img {
        min-height: 550px;
        width: 1920px;
        object-fit: cover;
      }

      .title {
        position: absolute;
        top: 40px;
        left: 190px;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        flex-direction: column;

        &.mainTitle {
          font-size: 32px;
        }
      }
    }
  }
}
</style>
