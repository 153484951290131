<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue'
import { getFileList, getStorageCapacity, downloadFile, deleteFileOrFolder, getStorageDataCenterList, initUserStorage } from '@/apis/storageOptions'
import { ElSelect, ElOption, ElButton, ElTable, ElTableColumn, ElTooltip, ElProgress } from 'element-plus'
import ConfirmDialog from '../InstancesCom/ConfirmDialog/ConfirmDialog.vue'
import { calcFileSize, bytes2GB, getLastPath } from '@/utils'
import sliceUpload from '@/utils/sliceUpload/sliceUpload'
import autolog from 'autolog.js'
import moment from 'moment'

const payTip =
  '单价：￥0.01/GB/日</br>计费规则：系统将以自然日使用的最大容量为计费容量</br>超出免费20GB的费用(元/日)=超出容量(GB) * 0.01元/GB/日</br>扣费时间为次日凌晨。如有超出免费容量，最低费用0.01元</br></br>如果需要最大超过200GB的容量，请与客服进行咨询。'

const currRegion = ref('')
const unInit = ref(false)
const loading = ref(true)

const sumStorage = ref(0)
const maxFreeStorage = ref(0)
const freeUsed = ref(0)
const payUsed = ref(0)
const maxDailyUsed = ref(0)
const todayPayment = ref(0)
const percentage = ref(0)
const uploading = ref(false)
const uploadStatus = ref('')
const isShowConfirmDelDialog = ref(false)
const currFileNum = ref('')

const freeBarWidth = computed(() => {
  return (freeUsed.value / sumStorage.value) * 100 + '%'
})
const payBarWidth = computed(() => {
  return (payUsed.value / sumStorage.value) * 100 + '%'
})
const maxDailyUsedWidth = computed(() => {
  return (maxDailyUsed.value / sumStorage.value) * 100 + '%'
})

const currPath = ref<string[]>(['/'])

const regionList = ref<
  {
    value: string
    label: string
    storage_gateway_url: string
  }[]
>([])

const tableData = ref<
  {
    fileName: string
    fileSize: string
    updateDateTime: string
    is_dir: boolean
  }[]
>([])

onMounted(async () => {
  unInit.value = true
  await reFreshRegionList()
  reFreshFileList('/', 0)
})

function handleRegionChange() {
  currPath.value = ['/']
  getStorageInfo()
  reFreshFileList()
}

function getStorageInfo() {
  getStorageCapacity(getCurrStorageCenterUrl()).then((res) => {
    if (res.data.size == 0) {
      unInit.value = true
      loading.value = false
      return
    } else {
      unInit.value = false
    }
    loading.value = false
    sumStorage.value = bytes2GB(res.data.size)
    freeUsed.value = bytes2GB(res.data.free_size)
    payUsed.value = bytes2GB(res.data.cost_size)
    maxDailyUsed.value = bytes2GB(res.data.max_day_used)
    todayPayment.value = res.data.pre_cost * 100
    maxFreeStorage.value = bytes2GB(res.data.free_cap)
  })
}

function reFreshFileList(path: string = currPath.value.slice(1).join('/'), timeout = 2000) {
  if (!currRegion.value) {
    return
  }
  getFileList(path || '/', getCurrStorageCenterUrl()).then((res) => {
    tableData.value = []
    for (let item of res.data) {
      tableData.value.push({
        fileName: item.name,
        fileSize: calcFileSize(item.size),
        updateDateTime: moment(item.mod_time).format('YYYY-MM-DD HH:mm:ss'),
        is_dir: item.is_dir
      })
    }
    setTimeout(() => {
      getStorageInfo()
    }, timeout)
  })
}

async function reFreshRegionList() {
  regionList.value = []
  let res = await getStorageDataCenterList()
  for (let item of res.data) {
    regionList.value.push({
      value: item.data_center_id,
      label: item.data_center_name,
      storage_gateway_url: item.storage_gateway_url
    })
  }
  currRegion.value = regionList.value[0].value
}

function initStorage() {
  if (!currRegion.value) {
    autolog.log('请先选择地区', 'error')
    return
  }
  initUserStorage(getCurrStorageCenterUrl()).then((res) => {
    if (res.code !== 200) {
      return
    }
    autolog.log('初始化成功', 'success')
    unInit.value = false
    getStorageInfo()
  })
}

function uploadFile(dir: string = currPath.value.slice(1).join('/')) {
  const file = document.createElement('input')
  file.type = 'file'
  file.click()
  file.onchange = (e) => {
    if (e.target === null) {
      autolog.log('未选择文件', 'error')
      return
    }
    const files = (e.target as HTMLInputElement).files
    if (!files) {
      autolog.log('未选择文件', 'error')
      return
    }
    if (files.length === 0) {
      return
    }
    uploading.value = true
    sliceUpload(files[0], dir, getCurrStorageCenterUrl(), (file_name, md5, progress) => {
      console.log(file_name, md5, progress + '%')
      percentage.value = progress
      if (progress === 100) {
        autolog.log('上传成功', 'success')
        uploadStatus.value = 'success'
        setTimeout(() => {
          uploading.value = false
          percentage.value = 0
          uploadStatus.value = ''
        }, 1000)
        reFreshFileList()
      }
    }).catch((err) => {
      console.error('err::: ', err)
      uploadStatus.value = 'exception'
      autolog.log('上传失败', 'error')
      setTimeout(() => {
        uploading.value = false
        percentage.value = 0
        uploadStatus.value = ''
      }, 1000)
    })
  }
}
function pullupDialog(fileName: string) {
  isShowConfirmDelDialog.value = true
  currFileNum.value = fileName
}
function getDownloadFile(fileName: string) {
  downloadFile(getCurrStorageCenterUrl(), fileName)
}
function rmFileOrFolder(fileName: string = currFileNum.value) {
  deleteFileOrFolder(getCurrStorageCenterUrl(), fileName).then((res) => {
    if (res.code !== 200) {
      return
    }
    autolog.log('删除成功', 'success')
    isShowConfirmDelDialog.value = false
    reFreshFileList()
  })
}
function goDir(path: string, isDir: boolean) {
  if (path == currPath.value[currPath.value.length - 1]) {
    return
  }
  if (isDir) {
    if (path === '/') {
      currPath.value = ['/']
    } else {
      if (currPath.value.includes(path)) {
        currPath.value = currPath.value.slice(0, currPath.value.indexOf(path) + 1)
      } else {
        currPath.value.push(path)
      }
    }
    reFreshFileList(currPath.value.slice(1).join('/'))
  }
}

function getCurrStorageCenterUrl() {
  let url = regionList.value.find((item) => item.value === currRegion.value)?.storage_gateway_url
  if (!url) {
    autolog.log('未找到所选的数据中心', 'error')
    return ''
  }
  return url
}

watch(uploading, (val) => {
  if (val) {
    // 监听tab关闭事件，询问用户是否关闭
    window.onbeforeunload = function () {
      return '文件上传中，确定要离开吗？'
    }
  } else {
    window.onbeforeunload = null
  }
})
</script>

<template>
  <div class="StoragesComBox" :loading="loading">
    <!--地区-->
    <div class="StoragesArea">
      <div class="select">
        <el-select v-model="currRegion" filterable placeholder="地区选择" style="width: 180px !important" @change="handleRegionChange">
          <el-option v-for="item in regionList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="tip" v-if="!unInit">
        <div class="icon">
          <span class="iconfont icon-tishi"></span>
        </div>
        <div class="title">
          文件存储最大允许 {{ sumStorage }} GB，挂载在系统的<span style="color: var(--theme-main-color)"> /root/gpufree-fs</span>
          。三个月未登录或者欠费，将会被删除。
        </div>
      </div>
    </div>
    <!--初始化-->
    <div v-if="!unInit && !loading">
      <!--存储-->
      <div class="storageProgress">
        <div class="storageProgresstTitle">
          <div class="free">
            <span class="iconfont icon-liwu1"></span>
            <span>免费</span>
          </div>
          <div class="pay" :style="{ left: freeBarWidth }" v-if="payBarWidth !== '0%'">
            <span class="iconfont icon-liwu1"></span>
            <span>付费</span>
          </div>
          <div class="icon">
            <el-tooltip :content="payTip">
              <template #content>
                <div v-html="payTip" style="font-size: 14px"></div>
              </template>
              <span class="iconfont icon-tanhao1" style="cursor: pointer"></span>
            </el-tooltip>
            <span style="color: #86c13d" class="number">
              {{ freeUsed }}
            </span>
            <span> + </span>
            <span style="color: var(--theme-main-color)" class="number">
              {{ payUsed }}
            </span>
            GB / <span>{{ sumStorage }} GB</span>
          </div>
        </div>
        <!--进度条-->
        <div class="progress">
          <div class="sumBar">
            <div
              class="freeBar"
              :style="{
                width: freeBarWidth,
                borderRadius: freeBarWidth == '100%' ? '130px' : '130px 0 0 130px'
              }"></div>
            <div class="payBar" :style="{ width: payBarWidth, left: freeBarWidth }" v-if="freeBarWidth !== '100%' && payBarWidth !== '0%'"></div>
            <div class="maxDailyUsedWidth" v-if="false" :style="{ width: maxDailyUsedWidth, left: 0 }"></div>
          </div>
        </div>
        <div class="abs btm12 ri12 fz14 d:f bg#fff">
          <span> 今天峰值 {{ maxDailyUsed.toFixed(2) }} GB，预计扣费 {{ todayPayment.toFixed(2) || ' 0.00 ' }} 元</span>
        </div>
      </div>
      <!--列表-->
      <div class="StoragesForm">
        <div class="form" :loading="loading">
          <div class="btn">
            <div class="currPath">
              <span v-for="item in currPath" @click="goDir(item, true)">
                {{ getLastPath(item, true) || '/' }}
              </span>
            </div>
            <div class="formTip">文件与目录数量超过300条,最多显示300条记录</div>
            <el-button type="warning" @click="uploadFile()"> <span class="iconfont icon-shangchuan"></span>上传 </el-button>
          </div>
          <!--表格-->
          <div class="table">
            <el-table :data="tableData" header-row-class-name="tableHeader">
              <el-table-column prop="fileName" label="文件名" align="left" class-name="fileOrfolderName">
                <template #default="scope">
                  <div @click="goDir(scope.row.fileName, scope.row.is_dir)" :class="{ dir: scope.row.is_dir }">
                    <span class="iconfont fileName" :class="`${scope.row.is_dir ? 'icon-wenjianjia' : 'icon-wenjian'}`"></span>
                    <span>{{ getLastPath(scope.row.fileName, scope.row.is_dir) }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="fileSize" label="大小" align="left" />
              <el-table-column prop="updateDateTime" label="更新时间" align="center" />
              <el-table-column fixed="right" label="操作" align="center">
                <template #default="scope">
                  <el-button link type="primary" size="small" @click="getDownloadFile(scope.row.fileName)" v-if="!scope.row.is_dir">下载 </el-button>
                  <el-button link type="primary" size="small" @click="pullupDialog(scope.row.fileName)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!--数据为空-->
    <div class="initial" v-if="!loading && unInit">
      <div class="initialContent">
        <div class="notInit">
          <img src="@/assets/imgs/notInit.png" alt="" />
        </div>
        <div class="initialtitle">该区域未分配存储空间，如需配置请点击<span style="color: var(--theme-main-color)">初始化</span></div>
        <div class="initialbtn" @click="initStorage">
          <span>初始化</span>
        </div>
      </div>
    </div>
  </div>
  <div class="mask" v-if="uploading">
    <div style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; flex-direction: column; gap: 20px; color: #fff">
      <span> 请勿离开页面，文件上传中...</span>
      <el-progress type="circle" :percentage="percentage" :status="uploadStatus ? 'success' : ''" />
    </div>
  </div>
  <ConfirmDialog v-if="isShowConfirmDelDialog" title="确认删除文件" @cancel="isShowConfirmDelDialog = false" @confirm="rmFileOrFolder">
    <div class="warn tip">
      <span class="iconfont icon-tishi"></span>
      文件删除后无法恢复
    </div>
  </ConfirmDialog>
</template>
<style lang="less" scoped>
.StoragesComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  display: flex;
  flex-direction: column;

  .StoragesArea {
    margin-top: 16px;
    margin-left: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .select {
      border-radius: 8px;
      margin-right: 22px;

      .el-select {
        width: 105px !important;
      }
    }

    .tip {
      display: flex;
      background: #fff7e8;
      align-items: center;
      color: var(--theme-main-color);
      .icon {
        display: flex;
        align-items: center;
        padding: 8px 5px 9px 16px;
        span {
          font-size: 12px;
        }
      }

      .title {
        font-family: AlibabaPuHuiTi_2_55_Regular;
        font-size: 12px;
        color: #1d2129;
        line-height: 17px;
        text-align: left;
        font-style: normal;
        padding-right: 12px;
      }
    }
  }

  .storageProgress {
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #ebebeb;
    margin-bottom: 16px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100px;
    .storageProgresstTitle {
      display: flex;
      padding: 13px 17px 8px 17px;
      justify-content: space-between;
      position: relative;

      .pay {
        position: absolute;
        padding-left: 20px;
      }

      .iconfont {
        padding-right: 5px;
      }
    }

    .progress {
      padding-left: 17px;
      padding-right: 17px;
      padding-bottom: 43px;

      .sumBar {
        height: 12px;
        border-radius: 130px;
        background: #e7e5f0;
        position: relative;
        transition: 1s;

        .freeBar {
          height: 100%;
          background: #86c13d;
          border-radius: 130px 0 0 130px;
          transition: 1s;
          width: 0;
          min-width: 6px;
          left: 0;
          top: 0;
          z-index: 2;
          position: absolute;
        }
        .maxDailyUsedWidth {
          height: 100%;
          border: 1px solid #f19100;
          border-radius: 130px 0 0 130px;
          transition: 1s;
          width: 0;
          top: 0;
          position: absolute;
          min-width: 6px;
        }
        .payBar {
          height: 100%;
          background: var(--theme-main-color);
          position: absolute;
          top: 0;
          border-radius: 0 130px 130px 0;
          transition: 1s;
          min-width: 6px;

          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: var(--theme-main-color);
            top: -12px;
            left: 0;
          }

          &::after {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #fff;
            top: -20px;
            left: 0;
            transform: translateX(-50%);
            box-sizing: border-box;
            border-top: 10px solid var(--theme-main-color);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
          }
        }
      }
    }
  }

  .StoragesForm {
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #ebebeb;
    margin-bottom: 24px;
    margin-right: 20px;
    margin-left: 20px;
    min-height: 150px;
    .form {
      padding: 12px 25px 0 25px;

      .dir {
        cursor: pointer;

        .iconfont {
          color: var(--theme-main-color);
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .fileName {
        font-size: 16px;
        padding-right: 5px;
      }
      .table {
        display: flex;
        > div {
          width: 0 !important;
          flex: 1;
        }
      }
      .btn {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 9px;
        align-items: center;
        position: relative;

        .currPath {
          position: absolute;
          left: 0;

          span {
            cursor: pointer;
            background: #f1f5f9;
            padding: 2px 6px;
            border: 1px solid #d9d9d9;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .formTip {
          margin-right: 10px;
          color: var(--theme-main-color);
        }
      }
    }
  }

  .initial {
    display: flex;
    background: white;
    margin-left: 20px;
    margin-right: 20px;
    height: 100%;
    margin-bottom: 20px;
    position: relative;
    align-items: center;
    justify-content: center;

    .initialContent {
      display: flex;
      align-items: center;
      flex-direction: column;

      .notInit {
        width: 60px;

        img {
          width: 100%;
        }
      }

      .initialtitle {
        padding-top: 21px;
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 21px;
        color: #9c9c9c;
      }

      .initialbtn {
        span {
          padding: 6px 24px;
          color: #ffffff;
          background: var(--theme-main-color);
          border-radius: 4px;
          cursor: pointer;

          &:hover {
            background: #f19100e8;
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.el-select__wrapper {
  cursor: pointer;
  padding: 6px 12px;
}

.el-progress__text {
  padding-left: 20px;
}

.el-table .cell {
  padding: 9px 12px;
}

.el-table__header-wrapper th {
  background-color: #f2f3f5;
  color: black;
}

.fileOrfolderName {
  &:hover {
    cursor: pointer;
  }
}
</style>
<style lang="less">
.el-progress__text {
  color: #fff;
  padding-left: 0;

  span {
    color: #fff;
  }
}
</style>
