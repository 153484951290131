import autolog from 'autolog.js'

export function calcFileSize(bytes: number) {
  if (bytes === 0) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}
export function bytes2GB(bytes: number) {
  if (typeof bytes !== 'number') return bytes
  return Number((bytes / 1024 / 1024 / 1024).toFixed(2))
}
export function GB2Bytes(GB: number) {
  return GB * 1024 * 1024 * 1024
}
export function copyInnerText(text?: string, tip: boolean = true) {
  if (!text) return
  const input = document.createElement('input')
  input.value = text
  document.body.appendChild(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
  tip && autolog.log('复制成功', 'success')
}
export function getLastPath(path: string, isDir: boolean = false) {
  const lastPath = path.split('/').reverse()[isDir ? 1 : 0]
  return lastPath
}
export function str2Asterisk(str: string, start: number = 3, end: number = 4) {
  if (!str) return ''
  const len = str.length
  if (len > 12) return str.substring(0, start) + '****' + str.substring(len - end)
  let result = str
  try {
    result = str.substring(0, start) + '*'.repeat(len - start - end) + str.substring(len - end)
  } catch (e) {}
  return result
}
export function getUrlWithouParams(url: string) {
  return url.split('?')[0]
}
