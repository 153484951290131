<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { ElDatePicker, ElTable, ElTableColumn, ElPagination } from 'element-plus'
import moment from 'moment'
import { getBalance } from '@/apis/bills'
import router from '@/router'

const size = 10

const value1 = ref('')
const page = ref(1)
const startTime = ref('')
const endTime = ref('')
const sort = ref<'asc' | 'desc'>('asc')
const sortby = ref('time')
const tatolPage = ref(0)
const tableData = ref()
const typeColor: {
  [key: string]: {
    color: string
    symbol: string
  }
} = {
  充值: {
    color: '#67C23A',
    symbol: '+'
  },
  消费: {
    color: '#F56C6C',
    symbol: '-'
  },
  退款: {
    color: '#67C23A',
    symbol: '+'
  }
}

onMounted(() => {
  getBalanceData()
})

function getBalanceData() {
  getBalance({
    page: page.value,
    size,
    start: startTime.value,
    end: endTime.value,
    sort: sort.value,
    sortby: sortby.value
  }).then((res) => {
    tableData.value = res.data
    tatolPage.value = res.total
  })
}

function handlePageDown() {
  page.value--
  getBalanceData()
}
function handlePageUp() {
  page.value++
  getBalanceData()
}
function changePage(val: number) {
  page.value = val
  getBalanceData()
}
function dateChange(val: string[]) {
  startTime.value = val[0]
  endTime.value = val[1]
  getBalanceData()
}
function goRoute(name: string) {
  router.push({ name })
}
</script>

<template>
  <div class="BalanceComBox">
    <div class="body">
      <div class="header">收支明细</div>
      <div class="options">
        <div class="datePicker">
          <span>变动查询</span>
          <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="default" @change="dateChange" />
        </div>
        <div class="recharge">
          <span class="btn" @click="goRoute('充值中心')"> 充值 </span>
        </div>
      </div>
      <div class="dataTable">
        <el-table :data="tableData" style="width: 100%" header-row-class-name="tableHeader">
          <el-table-column prop="time" label="时间">
            <template #default="{ row }">
              <span>{{ moment(row.time).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="流水ID" align="center"> </el-table-column>
          <el-table-column prop="transaction_type" label="交易类型" align="center">
            <template #default="{ row }">
              <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ row.transaction_type }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="transaction_way" label="渠道类型" align="center"> </el-table-column>
          <el-table-column prop="amount" label="交易金额(￥)" align="center">
            <template #default="{ row }">
              <span :style="{ color: typeColor[row.type]?.color }">{{ typeColor[row.type]?.symbol }}{{ row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="balance" label="交易后余额(￥)" align="center"> </el-table-column>
          <el-table-column prop="note" label="备注" align="center"> </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination background layout="prev, pager, next" :total="tatolPage" @prev-click="handlePageDown" @next-click="handlePageUp" @change="changePage" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.BalanceComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px;

  .body {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border: 1px solid #ebebeb;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .pages {
      display: flex;
      justify-content: flex-end;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .dataTable {
      flex: 1;
    }

    .header {
      font-size: 16px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-bottom: 34px;
    }

    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 2px;
      .datePicker {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 20px;
        gap: 20px;
        width: max-content;

        span {
          font-size: 14px;
          color: #4e5969;
        }
      }
      .recharge {
        .btn {
          font-size: 12px;
          color: #ffffff;
          background: var(--theme-main-color);
          border-radius: 4px;
          padding: 4px 20px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
