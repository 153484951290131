<script setup lang="ts"></script>

<template>
  <div class="BaseMainContentBox">
    <router-view />
  </div>
</template>

<style lang="less" scoped>
.BaseMainContentBox {
  width: 100%;
  height: calc(100vh - var(--header-height));
}
</style>
