import { post, get } from '@/utils/request/request'

const oidc = {
  login: (params: API.LoginPassword | API.LoginCode) => {
    return post('/api/v1/oidc/user/sign-in', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  logout: () => {
    return get('/api/v1/user/sign-out', {})
  },
  getUserInfo: () => {
    return get('/api/v1/oidc/userinfo', {}, { localcache: true })
  },
  changePassword: (params: API.ChangePassword) => {
    return post('/api/v1/oidc/user/change-password', params)
  },
  getImageVCode: (params: { type: 'slide' | 'click' | 'rotate'; sub_type: string }) => {
    return get('/api/v1/oidc/captcha', params)
  },
  checkImageVCode: (params: { key: string; point: string }) => {
    return post('/api/v1/oidc/captcha/verify?type=slide', params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  getSmsVCode: (params: { mobile: string; captcha: string; reqId?: string; type: string }) => {
    return post('/api/v1/oidc/sms', params)
  }
}
export default oidc
