import { defineStore } from 'pinia'
import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark()
const toggleDark = useToggle(isDark)

export const mainStore = defineStore('mainStore', {
  state: () => ({
    isDarkMode: false,
    mode: 'dark' as 'dark' | 'light',
    isShowBalance: true,
    lastLoginTime: 0,
    currPaymentOptions: {
      paymentMethod: '' as 'alipay' | 'wxpay',
      paymentAmount: 0
    },
    lastRand_num: '',
    lastSerial_num: '',
    memoryCache: {} as Record<string, any>
  }),
  persist: {
    pick: ['mode', 'isShowBalance', 'lastRand_num', 'lastSerial_num', 'memoryCache']
  },
  getters: {},
  actions: {
    switchTheme(mode?: 'dark' | 'light') {
      this.isDarkMode = mode == 'dark' || (mode == undefined && !this.isDarkMode)
      toggleDark(this.isDarkMode)
      this.mode = mode || (this.isDarkMode ? 'dark' : 'light')
    },
    toggleShowBalance() {
      this.isShowBalance = !this.isShowBalance
    }
  }
})
