import SparkMD5 from 'spark-md5'

import { upload, checkFile } from '@/apis/storageOptions'
import autolog from 'autolog.js'

// 计算文件md5
async function calculateMd5(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer()
    const reader = new FileReader()
    reader.onload = (event) => {
      spark.append(event.target?.result as ArrayBuffer)
      if (spark.end()) {
        resolve(spark.end())
      }
    }
    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}

// 上传文件切片
function uploadChunk(file_name: string, index: number, chunk: Blob, chunks: number, dir: string = '/', md5: string, url: string, callback?: (file_name: string, md5: string) => void) {
  const formData = new FormData()
  formData.append('file', chunk)
  formData.append('chunk_offset', index.toString())
  formData.append('chunks', chunks.toString())
  formData.append('dir', dir)
  formData.append('file_name', file_name)
  formData.append('md5', md5)
  return new Promise((resolve) => {
    upload(url, formData).then((res) => {
      if (res.code === 200) {
        if (callback) {
          callback(file_name, md5)
        }
      }
      resolve(res)
    })
  })
}

// 线程池实现
class ThreadPool {
  private queue: (() => Promise<void>)[] = []
  private activeCount = 0
  private readonly maxConcurrency: number

  constructor(maxConcurrency: number) {
    this.maxConcurrency = maxConcurrency
  }

  private async runTask(task: () => Promise<void>) {
    this.activeCount++
    try {
      task()
        .then(() => {
          this.activeCount--
          this.runNext()
        })
        .catch((error) => {
          this.errorExit(error)
        })
    } catch (error) {
      this.errorExit(error)
    }
  }

  private runNext() {
    if (this.queue.length > 0 && this.activeCount < this.maxConcurrency) {
      const nextTask = this.queue.shift()
      if (nextTask) {
        this.runTask(nextTask)
      }
    }
  }
  private errorExit(error: unknown) {
    console.error(error)
    autolog.log('上传出错', 'error')
    this.activeCount = 0
    this.queue = []
  }
  public addTask(task: () => Promise<void>) {
    this.queue.push(task)
    this.runNext()
  }
}

const threadPool = new ThreadPool(5)

async function sliceUpload(file: File, dir: string = '/', url: string, callback?: (file_name: string, md5: string, progress: number) => void) {
  const chunkSize = 1024 * 1024 * 1.2 // 1.2MB
  const chunks = Math.ceil(file.size / chunkSize) // 计算总块数
  const md5 = await calculateMd5(file) // 计算文件md5
  let existChunks: string[] = []
  let res = await checkFile(url, md5)
  existChunks = res.data
  let callbackCount = 0
  const responsePromises: Promise<void>[] = []

  for (let index = 0; index < chunks; index++) {
    const start = index * chunkSize
    const end = Math.min(start + chunkSize, file.size)
    const chunk = file.slice(start, end) // 切片文件
    if (existChunks.includes(index.toString())) {
      callbackCount++
      const progress = Math.floor((callbackCount / chunks) * 100)
      callback && callback(file.name, md5, progress)
      continue
    }
    const task = () => {
      return uploadChunk(file.name, index, chunk, chunks, dir, md5, url, (file_name: string, md5: string) => {
        if (callback) {
          callbackCount++
          const progress = Math.floor((callbackCount / chunks) * 100)
          callback(file_name, md5, progress)
        }
      })
    }
    responsePromises.push(
      new Promise<void>((resolve) => {
        threadPool.addTask(async () => {
          await task()
          resolve()
        })
      })
    )
  }

  await Promise.all(responsePromises)
}

export default sliceUpload
