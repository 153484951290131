import { post, get } from '@/utils/request/request'

// 上传，切片
export const upload = (url: string, formData: FormData) => {
  return post(`${url}/api/v1/storage/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 获取文件列表
export const getFileList = (path: string = '/', url: string) => {
  return get(`${url}/api/v1/storage/ls`, {
    path
  })
}
// 获取存储容量
export const getStorageCapacity = (url: string) => {
  return get(`${url}/api/v1/storage/cap`)
}
// 初始化用户存储
export const initUserStorage = (url: string) => {
  return post(`${url}/api/v1/storage/init`, {})
}
// 下载文件
export const downloadFile = (url: string, path: string) => {
  const fullurl = `${url}/api/v1/storage/download?path=${path}`
  window.open(fullurl)
}
// 获取数据中心列表
export const getStorageDataCenterList = () => {
  return get('/api/v1/file_storage/list')
}
// 删除文件或文件夹
export const deleteFileOrFolder = (url: string, path: string) => {
  return post(`${url}/api/v1/storage/rm`, { path })
}
// 秒传、续传查询
export const checkFile = (url: string, md5: string) => {
  return get(`${url}/api/v1/storage/file_check`, {
    md5
  })
}
