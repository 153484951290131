<script setup lang="ts">
import { getInstanceIndex, getStorageIndex } from '@/apis/consoleData'
import { ref, onMounted, onBeforeMount } from 'vue'
import { ElTooltip } from 'element-plus'
import { mainStore } from '@/store'
import autolog from 'autolog.js'
import router from '@/router'
import { bytes2GB } from '@/utils'
import { getBalanceQuery } from '@/apis/bills'

const store = mainStore()

const instanceIndex = ref({
  running: 0,
  total: 0,
  expired_warning_num: 0,
  release_warning_num: 0
})
const storageIndex = ref({
  data_disk_expand_all: 0,
  image_expand_all: 0,
  file_storage_expand_all: 0
})
const balance = ref(0.0)
const loading = ref(true)

onBeforeMount(async () => {
  let res = await getInstanceIndex()
  instanceIndex.value = res.data
  getStorageIndex().then((res) => {
    storageIndex.value = res.data
    setTimeout(() => {
      loading.value = false
    }, 5000)
  })
})

onMounted(() => {
  getBalanceNow()
})

async function getBalanceNow() {
  balance.value = (await getBalanceQuery()).data.balance / 100
}
function tgShowBalance() {
  store.toggleShowBalance()
}
// function goNotice() {
//   autolog.log('暂未开放，敬请期待', 'info')
// }
function changeNickName() {
  goRoute('/console/user')
}
function goRoute(path?: string) {
  if (!path) {
    autolog.log('暂未开放，敬请期待', 'info')
    return
  }
  router.push(path)
}
</script>

<template>
  <div class="OverviewComBox">
    <div class="indexGridBox">
      <div class="indexGridItem" @click="goRoute('/console/instances?status=3')">
        <span class="iconfont icon-yunhang"></span>
        <div class="value">{{ instanceIndex.running }}</div>
        <div class="title">运行中</div>
      </div>
      <div class="indexGridItem" @click="goRoute('/console/instances')">
        <span class="iconfont icon-GPU"></span>
        <div class="value">{{ instanceIndex.total }}</div>
        <div class="title">总量</div>
      </div>
      <div class="indexGridItem" @click="goRoute('/console/instances?sort=2')">
        <span class="iconfont icon-daoqi"></span>
        <div class="value">{{ instanceIndex.expired_warning_num }}</div>
        <div class="title">即将到期</div>
      </div>
      <div class="indexGridItem" @click="goRoute('/console/instances?sort=2')">
        <span class="iconfont icon-shifang"></span>
        <div class="value">{{ instanceIndex.release_warning_num }}</div>
        <div class="title">即将释放</div>
      </div>
      <div class="indexGridItem user">
        <div class="head">
          <div class="username">
            <span>炼丹师9549</span>
            <span class="iconfont icon-bianji1" @click="changeNickName"></span>
          </div>
        </div>
        <div class="userviptype">
          <img src="@/assets/imgs/vip.png" alt="" />
        </div>
        <div class="wallet">
          <div class="walletHead">
            <span>可用额度</span>
            <span class="iconfont" :class="`${store.isShowBalance ? 'icon-yulan-kejian' : 'icon-yulan-bukejian'}`" @click="tgShowBalance"></span>
          </div>
          <div class="balance">
            ￥
            <span> {{ store.isShowBalance ? balance.toFixed(2) : '***' }}</span>
          </div>
          <div class="options">
            <span @click="goRoute('/console/bills/recharge')">充值</span>
          </div>
        </div>
        <div class="routes">
          <div class="routeItem" @click="goRoute('/market')">
            <span class="iconfont icon-suanli1"></span>
            <span>租用算力</span>
          </div>
          <div class="routeItem" @click="goRoute('/console/user')">
            <span class="iconfont icon-geren"></span>
            <span>个人中心</span>
          </div>
          <div class="routeItem" @click="goRoute('/console/bills')">
            <span class="iconfont icon-feiyong1"></span>
            <span>费用中心</span>
          </div>
        </div>
        <div class="links">
          <div class="linkItem" @click="goRoute('/console/user/secrets')">
            <span>密钥管理</span>
          </div>
          <div class="linkItem" @click="goRoute('/console/bills')">
            <span>我的账单</span>
          </div>
        </div>
      </div>
      <div class="indexGridItem">
        <div class="storageGrid">
          <div class="storageGridItem">
            <div class="title">
              实例存储
              <el-tooltip content="提示信息" placement="top-start">
                <span class="iconfont icon-xinxishuoming"></span>
              </el-tooltip>
            </div>
            <div class="subtitle">付费扩容容量</div>
            <div class="value">
              <span>
                {{ bytes2GB(storageIndex.data_disk_expand_all) }}
              </span>
              <span class="unit">GB</span>
            </div>
          </div>
          <div class="storageGridItem" @click="goRoute('/console/storages')">
            <div class="title">
              文件存储
              <el-tooltip content="提示信息" placement="top-start">
                <span class="iconfont icon-xinxishuoming"></span>
              </el-tooltip>
            </div>
            <div class="subtitle">付费容量</div>
            <div class="value">
              <span>
                {{ bytes2GB(storageIndex.file_storage_expand_all) }}
              </span>
              <span class="unit">GB</span>
            </div>
          </div>
          <div class="storageGridItem">
            <div class="title">
              镜像存储
              <el-tooltip content="提示信息" placement="top-start">
                <span class="iconfont icon-xinxishuoming"></span>
              </el-tooltip>
            </div>
            <div class="subtitle">付费容量</div>
            <div class="value">
              <span>
                {{ bytes2GB(storageIndex.image_expand_all) }}
              </span>
              <span class="unit">GB</span>
            </div>
          </div>
        </div>
      </div>
      <div class="indexGridItem">
        <div class="quickStartGrid">
          <div class="quickStartGridItem">
            <div class="qrcode">
              <img src="@/assets/imgs/officialaccount.jpg" alt="" />
              <span>公众号</span>
            </div>
            <div class="qrcode">
              <img src="@/assets/imgs/contact.png" alt="" />
              <span>联系客服</span>
            </div>
          </div>
          <div class="quickStartGridItem promotion" @click="goRoute()">
            <div class="promotionSwiper">
              <img src="@/assets/imgs/promotion_demo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="indexGridItem help" @click="goRoute()">
        <span class="title">帮助文档</span>
        <div class="quickStart">
          <span>快速进入</span>
          <span>▶</span>
          <span>▶</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.OverviewComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px 22px;
  border-left: 1px solid #e7e7e7;

  .indexGridBox {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 1.56fr;
    grid-template-rows: repeat(2, 1fr) 1.15fr;
    gap: 15px;

    .indexGridItem {
      background: #fff;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
      border-radius: 8px;

      &:not(.user) {
        cursor: pointer;
        gap: 20px;
      }

      &.user {
        padding: 20px;
        justify-content: flex-start;

        .head {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;

          .iconfont {
            padding-left: 10px;
            padding-right: 5px;
            color: #4e5969;
            cursor: pointer;

            &:hover {
              color: var(--theme-main-color);
            }
          }
        }

        .notice {
          cursor: pointer;
        }

        .userviptype {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          margin-top: 10px;
          height: 24px;
          margin-bottom: 20px;

          img {
            height: 100%;
          }
        }

        .wallet {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          width: 100%;
          padding: 12px 20px;
          background-image: url('@/assets/imgs/walletbg.png');
          background-size: 180%;
          background-position: -10% 35%;
          background-color: #fff2df;
          border-radius: 8px;

          .walletHead {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 12px;
            color: #50473b;
            padding-bottom: 5px;

            .iconfont {
              font-size: 16px;
              color: #433a2c;
              cursor: pointer;
            }
          }

          .balance {
            font-size: 20px;
            color: #433a2c;
            width: 100%;
            padding-bottom: 20px;

            span {
              color: var(--theme-main-color);
              font-size: 36px;
            }
          }

          .options {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            span {
              font-size: 12px;
              color: #fff;
              cursor: pointer;
              border: 1px solid var(--theme-main-color);
              background: var(--theme-main-color);
              padding: 2px 10px;
              line-height: 17px;
              border-radius: 4px;

              &:hover {
                background: var(--theme-main-color);
                color: #fff;
              }
            }
          }
        }

        .routes {
          display: grid;
          align-items: center;
          grid-template-columns: repeat(3, 1fr);
          width: 100%;
          margin-top: 20px;
          gap: 10px;

          .routeItem {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #fff2df;
            flex: 1;
            padding: 10px;
            border-radius: 8px;
            height: max-content;
            white-space: nowrap;

            span {
              font-size: 12px;
              color: #000000;
            }

            .iconfont {
              font-size: 16px;
              padding-right: 5px;
              color: var(--theme-main-color);
            }
          }
        }

        .links {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
          position: absolute;
          bottom: 0;

          .linkItem {
            display: flex;
            cursor: pointer;
            justify-content: center;
            flex: 1;
            padding: 10px 0;
            border: 1px solid #e5e7eb;
            border-bottom: none;
            margin-right: -1px;

            &:last-child {
              border-right: none;
            }

            &:first-child {
              border-left: none;
            }

            span {
              font-size: 14px;
              color: #442f0d;
              cursor: pointer;
              line-height: 20px;
              text-decoration: solid underline;

              &:hover {
                color: var(--theme-main-color);
              }
            }
          }
        }
      }

      &:nth-child(5) {
        grid-row: span 2;
      }

      &:nth-child(6) {
        grid-column: span 4;
        grid-row: span 1;
      }

      &:nth-child(7) {
        grid-column: span 4;
        grid-row: span 1;
        background: none;
        padding: 0;
      }

      > .iconfont {
        font-size: 24px;
        color: #4e5969;
        position: absolute;
        left: 30px;
        top: 24px;
      }

      .title {
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        font-weight: bold;
      }

      .value {
        font-size: 36px;
        color: #000000;
        line-height: 50px;
      }

      .storageGrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .storageGridItem {
          background: #f9fbf6;
          border-radius: 12px;
          padding: 14px 0 20px 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          .iconfont {
            font-size: 16px;
            color: #9c9c9c;
            font-weight: 100;
            cursor: pointer;
            padding-left: 5px;
          }

          .title {
            font-size: 14px;
            color: #000000;
            line-height: 20px;
            font-weight: bold;
          }

          .subtitle {
            font-size: 12px;
            color: #9c9c9c;
            padding-top: 5px;
          }

          .value {
            font-size: 36px;
            color: #213547;

            .unit {
              font-size: 24px;
              padding-left: 5px;
            }
          }
        }
      }

      .quickStartGrid {
        display: grid;
        grid-template-columns: 0.835fr 1fr;
        gap: 15px;
        width: 100%;
        height: 100%;
        background: none;

        .quickStartGridItem {
          background: #ffffff;
          border-radius: 12px;
          padding: 26px;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          &.promotion {
            padding: 0;
            height: 100%;
            cursor: pointer;

            .promotionSwiper {
              width: 100%;
              height: 100%;
              overflow: hidden;
              border-radius: 12px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 100%;
                width: 100%;
                max-height: 215px;
                object-fit: cover;
              }
            }
          }

          .qrcode {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            img {
              width: 124px;
            }

            span {
              color: #4e5969;
            }
          }
        }
      }

      &.help {
        background-image: url('@/assets/imgs/helpbg.png');
        background-position: 0 50%;
        background-size: 100%;
        height: 100%;
        padding: 0;
        cursor: pointer;

        .title {
          position: absolute;
          top: 24px;
          right: 45px;
          font-size: 24px;
          color: #000000;
        }

        .quickStart {
          position: absolute;
          top: 75px;
          right: 45px;
          font-size: 14px;
          cursor: pointer;
          display: flex;
          gap: 4px;

          span {
            color: var(--theme-main-color);

            &:first-child {
              color: #000000;
              padding-right: 2px;
            }

            &:nth-child(3) {
              color: var(--theme-active-bg);
            }
          }
        }
      }
    }
  }
}
</style>
