<script setup lang="ts">
import { routes } from '@/router'
import { ElCollapse, ElCollapseItem, ElScrollbar } from 'element-plus'
import { ref } from 'vue'

let consoleLinks = routes[0].children?.filter((route) => route.path == 'console')[0].children
const activeName = ref('')
</script>

<template>
  <div class="BaseMenuBox">
    <el-scrollbar>
      <div class="routerLinks">
        <template v-for="route in consoleLinks?.filter((item) => !item.meta?.hidden)" :key="route.path">
          <router-link :to="{ name: route.name }">
            <span class="iconfont" :class="route.meta?.icon"></span>
            <span>{{ route.name }}</span>
          </router-link>
        </template>
      </div>
      <div class="routerLinks bottom">
        <el-collapse v-model="activeName" accordion class="routeCollapse">
          <template v-for="route in consoleLinks?.filter((item) => !!item.meta?.hidden)" :key="route.path">
            <!-- @vue-skip -->
            <el-collapse-item :title="route.name" :name="route.path">
              <template #title>
                <span class="iconfont" :class="route.meta?.icon"></span>
                <span>{{ route.name }}</span>
              </template>
              <router-link v-for="item in route.children.filter((item) => !item.meta.hidden)" :to="{ name: item.name }">
                <span class="iconfont" :class="item.meta?.icon"></span>
                <span>{{ item.name }}</span>
              </router-link>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
    </el-scrollbar>
  </div>
</template>

<style lang="less" scoped>
.BaseMenuBox {
  :deep(.el-scrollbar__view) {
    width: var(--menu-width);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 8px 12px;
    padding-bottom: 62px;
  }

  .routerLinks {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    font-size: 14px;
    a {
      padding: 12px 30px;
      width: 100%;
      display: inline-block;
      transition: 0.2s;

      .iconfont {
        margin-right: 18px;
        display: inline-block;
        width: 18px;
      }

      &.router-link-active {
        background-color: #f1f2f5;
        color: var(--theme-active-color);
        border-radius: 6px;
      }

      &:not(.router-link-active):hover {
        background-color: #f5f5f5;
      }
    }

    &.bottom :deep(.el-collapse-item__content) {
      display: flex;
      flex-direction: column;
      gap: 6px;
      width: 100%;

      a {
        padding: 12px 30px;
        width: 100%;
        display: inline-block;
        transition: 0.2s;
        border-radius: 6px;
        color: var(--theme-color);
        line-height: 1.2;

        .iconfont {
          margin-right: 34px;
          width: max-content;
        }

        &.router-link-active {
          color: var(--theme-active-color);
        }

        &:not(.router-link-active):hover {
          color: var(--theme-active-color);
          background-color: #f5f5f5;
        }
      }
    }
  }
}
</style>
<style lang="less">
.routerLinks {
  .routeCollapse {
    border: none;
  }

  .el-collapse-item__wrap {
    border: none;
  }

  .el-collapse-item {
    border: none;

    button {
      border: none;
    }
  }

  .el-collapse-item__header {
    padding: 12px 30px;
    padding-right: 0;

    .iconfont {
      margin-right: 18px;
    }
  }
}
</style>
