<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { ElInput, ElTable, ElTableColumn, ElScrollbar } from 'element-plus'
import { getBalance, getBalanceQuery } from '@/apis/bills'
import router from '@/router'
import { mainStore } from '@/store'
import moment from 'moment'

const store = mainStore()
const size = 10
const sortby = ref('time')
const sort = ref<'asc' | 'desc'>('asc')
const typeColor: {
  [key: string]: {
    color: string
    symbol: string
  }
} = {
  充值: {
    color: '#67C23A',
    symbol: '+'
  },
  消费: {
    color: '#F56C6C',
    symbol: '-'
  },
  退款: {
    color: '#67C23A',
    symbol: '+'
  }
}

const quickChargeAmount = ref([10, 20, 50, 100, 200, 500])
const rechargeAmount = ref(10)
const currBalance = ref(0)
const tableData = ref()
const paymentMethod = ref({
  wxpay: {
    name: '微信支付',
    icon: 'icon-weixinzhifu',
    color: '#1AAD19'
  }
})
const currentPaymentMethod = ref<'alipay' | 'wxpay'>('wxpay')

onMounted(() => {
  getBalanceNow()
  getBalanceData()
})

function getBalanceData() {
  getBalance({
    page: 1,
    size,
    sort: sort.value,
    sortby: sortby.value
  }).then((res) => {
    tableData.value = res.data
  })
}

async function getBalanceNow() {
  currBalance.value = (await getBalanceQuery()).data.balance / 100
}
const submit = () => {
  if (!rechargeAmount.value) {
    console.log('请输入充值金额')
    return
  }
  store.currPaymentOptions = {
    paymentMethod: currentPaymentMethod.value,
    paymentAmount: rechargeAmount.value
  }
  router.push({
    name: '支付'
  })
}
</script>

<template>
  <div class="RechargeComBox df">
    <div class="body">
      <div class="header">充值中心</div>
      <el-scrollbar>
        <div class="rechargeForm">
          <div class="formItem">
            <span>账户余额：</span>
            <div class="flex gap10px aife lh1">
              <span class="money fs24! fwb">{{ currBalance.toFixed(2) }}</span>
              <span class="unit">元</span>
            </div>
          </div>
          <div class="formItem aifs!">
            <span style="line-height: 36px">充值金额：</span>
            <div class="flex fdc gap15px">
              <div class="input flex aic gap8px">
                <el-input v-model="rechargeAmount" placeholder="请输入充值金额" />
                <span>元</span>
              </div>
              <div class="flex gap16px">
                <span v-for="item in quickChargeAmount" @click="rechargeAmount = item" class="quickChargeAmount" :class="{ active: rechargeAmount == item }">{{ item }}</span>
              </div>
            </div>
          </div>
          <div class="formItem">
            <span>支付方式：</span>
            <div class="flex gap12px">
              <div v-for="(item, index) in paymentMethod" class="paymentMethodItem" :class="{ active: index == currentPaymentMethod }" @click="currentPaymentMethod = index">
                <span class="iconfont" :class="item.icon" :style="{ color: item.color }"></span>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="formItem">
            <span class="op0">支付方式：</span>
            <div class="submit" @click="submit">
              <span>充值</span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="body">
      <div class="header">
        <span>充值历史</span>
        <span class="btn" @click="router.push({ name: `收支明细` })">更多历史</span>
      </div>
      <div class="dataTable">
        <el-table :data="tableData" style="width: 100%" header-row-class-name="tableHeader">
          <el-table-column prop="time" label="时间">
            <template #default="{ row }">
              <span>{{ moment(row.time).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="流水ID" align="center"> </el-table-column>
          <el-table-column prop="transaction_type" label="交易类型" align="center">
            <template #default="{ row }">
              <span :style="{ color: typeColor[row.transaction_type]?.color }">{{ row.transaction_type }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="transaction_way" label="渠道类型" align="center"> </el-table-column>
          <el-table-column prop="amount" label="交易金额(￥)" align="center">
            <template #default="{ row }">
              <span :style="{ color: typeColor[row.type]?.color }">{{ typeColor[row.type]?.symbol }}{{ row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="balance" label="交易后余额(￥)" align="center"> </el-table-column>
          <el-table-column prop="note" label="备注" align="center"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.RechargeComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px 22px;
  border-left: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .body {
    height: 60%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &:first-child {
      flex: 1;
      min-height: 384px;
    }
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid #ebebeb;
    .header {
      font-size: 16px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-bottom: 34px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .btn {
        cursor: pointer;
        font-size: 12px;
        line-height: 22px;
        padding: 4px 12px;
        border-radius: 4px;
        font-style: normal;
        background-color: #4273f8;
        color: #fff;
      }
    }
    .rechargeForm {
      height: 0;
      flex: 1;
      padding: 14px 80px;
      display: flex;
      flex-direction: column;
      gap: 27px;
      .formItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .submit {
          background: #f19200;
          border-radius: 2px;
          padding: 8px 20px;
          cursor: pointer;
          span {
            color: #ffffff;
          }
          &:hover {
            background: #f19200;
          }
        }
        .paymentMethodItem {
          padding: 6px 0;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #d1d9e0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          font-size: 16px;
          white-space: nowrap;
          gap: 8px;
          cursor: pointer;
          &.active {
            border: 1px solid #f19200;
            background: #fefaf3;
          }
          &:not(.active):hover {
            background: #f1f5f9;
          }
          .iconfont {
            font-size: 20px;
          }
        }
        :deep(.el-input) {
          width: 256px !important;
          font-size: 16px;
          input {
            padding: 3px 0;
            box-sizing: content-box;
          }
        }
        .quickChargeAmount {
          cursor: pointer;
          color: #4e5969;
          font-size: 16px;
          line-height: 22px;
          font-style: normal;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid #d1d9e0;
          width: 100px;
          padding: 7px 0;
          text-align: center;
          &:not(.active):hover {
            background: #f1f5f9;
          }
          &.active {
            border: 1px solid var(--theme-main-color);
            background: #fefaf3;
          }
        }
        span {
          font-size: 16px;
          color: #4e5969;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .money {
          font-size: 16px;
          color: #4e5969;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
        .unit {
          font-size: 16px;
          color: #4e5969;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
      }
    }
  }
}
</style>
