<script setup lang="ts">
import { mainStore } from '@/store'
import autolog from 'autolog.js'
import { onMounted, ref } from 'vue'
import { recharge } from '@/apis/bills'
import QrcodeVue from 'qrcode.vue'
import router from '@/router'

const store = mainStore()

const currPaymentOptions = JSON.parse(JSON.stringify(store.currPaymentOptions))
store.currPaymentOptions = {
  paymentMethod: '' as 'alipay' | 'wxpay',
  paymentAmount: 0
}
const qrcodeValue = ref('')
const loading = ref(true)
onMounted(async () => {
  if (!currPaymentOptions.paymentMethod) {
    autolog.log('未知的支付方式，请重试', 'error')
    loading.value = false
    return
  }
  if (!currPaymentOptions.paymentAmount) {
    autolog.log('支付金额错误，请重试', 'error')
    loading.value = false
    return
  }
  let res = await recharge({
    amount: currPaymentOptions.paymentAmount * 100,
    description: `余额充值`
  })
  qrcodeValue.value = res.data.code_url
  loading.value = false
})
</script>

<template>
  <div class="PaymentComBox">
    <div class="body">
      <div class="header">充值中心</div>
      <div class="flex fdc aic jcc paddtop40 gap10 fs16" v-if="qrcodeValue" :class="{ loading: loading }">
        <QrcodeVue :value="qrcodeValue" :size="300" level="H" render-as="svg" />
        <span>请使用{{ currPaymentOptions.paymentMethod == 'alipay' ? '支付宝' : '微信' }}扫码支付</span>
      </div>
      <div v-else-if="!loading" class="center paddtop40 flex failed">获取二维码失败</div>
      <div class="back">
        <span class="btn" @click="router.replace({ name: '充值中心' })">返回充值页</span>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.PaymentComBox {
  width: 100%;
  height: 100%;
  background: #f1f5f9;
  padding: 20px 22px;
  border-left: 1px solid #e7e7e7;
  .body {
    height: 100%;
    width: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    border: 1px solid #ebebeb;
    .header {
      font-size: 16px;
      color: #4e5969;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      padding-bottom: 34px;
    }
    .loading {
      svg {
        opacity: 0.5;
      }
    }
    .failed {
      flex-direction: column;
      gap: 20px;
    }
    .back {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .btn {
        cursor: pointer;
        color: #fff;
        background: var(--theme-main-color);
        padding: 8px 20px;
        border-radius: 4px;
      }
    }
  }
}
</style>
